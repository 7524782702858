import * as XLSX from "xlsx";

interface ExportExcelProps {
  data: any[]; // Dados que serão exportados
  fileName?: string; // Nome do arquivo a ser gerado (opcional)
  onExportComplete?: () => void; // Callback para resetar o estado após exportar
}

export const ExportExcel: React.FC<ExportExcelProps> = ({
  data,
  fileName = "relatorio.xlsx",
  onExportComplete,
}) => {
  const exportToExcel = () => {
    // Definir os títulos das colunas
    const columns = [
      "EMPRESA",
      "SITUAÇÃO",
      "ORIGEM",
      "NF",
      "PARCELA",
      "VALOR",
      "VENCIMENTO",
      "CODIGO",
      "FORNECEDOR",
    ];

    // Mapear os dados para o formato que o Excel espera
    const rows = data.map((row) => ({
      EMPRESA: row.EMPRESA,
      SITUAÇÃO: row.E2_SITUACAO,
      ORIGEM: row.E2_ORIGEM,
      NF: row.E2_NUM,
      PARCELA: row.E2_PARCELA,
      VALOR: row.E2_VALOR,
      VENCIMENTO: row.E2_VENCTOFOR,
      CODIGO: row.E2_FORNECE,
      FORNECEDOR: row.E2_NOMFOR,
    }));

    // Cria a planilha
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório");

    // Gera o arquivo Excel e faz o download
    XLSX.writeFile(workbook, fileName);

    // Chama o callback para indicar que a exportação foi concluída
    if (onExportComplete) {
      onExportComplete();
    }
  };

  // Chama o export quando o componente é montado (ou quando necessário)
  exportToExcel();

  return null; // O componente não renderiza nada, apenas executa a exportação
};
