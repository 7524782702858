import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IListagemFluxoCaixaDetalhado {
    E5_DATA: string; // Data da movimentação
    A6_NOME: string; // Nome do banco
    EMPRESA: string; // Empresa
    NATUREZA: string; // Natureza da movimentação
    TP2: string; // Tipo 2 (campo adicional de categorização)
    E5_MOEDA: string; // Moeda da transação
    E5_NATUREZ: string; // Natureza da conta
    E5_TIPODOC: string; // Tipo de documento
    E5_VLMULTA: number; // Valor da multa
    E5_HISTOR: string; // Histórico da transação
    DESCRICAO: string; // Descrição do movimento
    VAI_PARA_EXTRATO: string; // Indicador se vai para o extrato
    LOCALIZADOR: string; // Localizador da transação
    Entrada: number; // Valor de entrada
    Saida: number; // Valor de saída
    SaldoDia: number; // Saldo do dia acumulado
}

export interface IDetalheFluxoCaixaDetalhado {
    E5_DATA: string; // Data da movimentação
    A6_NOME: string; // Nome do banco
    EMPRESA: string; // Empresa
    NATUREZA: string; // Natureza da movimentação
    TP2: string; // Tipo 2 (campo adicional de categorização)
    E5_MOEDA: string; // Moeda da transação
    E5_NATUREZ: string; // Natureza da conta
    E5_TIPODOC: string; // Tipo de documento
    E5_VLMULTA: number; // Valor da multa
    E5_HISTOR: string; // Histórico da transação
    DESCRICAO: string; // Descrição do movimento
    VAI_PARA_EXTRATO: string; // Indicador se vai para o extrato
    LOCALIZADOR: string; // Localizador da transação
    Entrada: number; // Valor de entrada
    Saida: number; // Valor de saída
    SaldoDia: number; // Saldo do dia acumulado
}

type TListagemComTotalFuxoCaixaDetalhado = {
    data: IListagemFluxoCaixaDetalhado[];
    totalCount: number;
    totalValor: number;
};

// Serviço para busca sem paginação com filtros adicionais
const getAllWithoutPagination = async (
    banco: string,
    empresa: string,
    dataInicio: string,
    dataFim: string,
    incluirProvReceber: boolean = true,
    incluirProvComercial: boolean = true,
    incluirProvPagar: boolean = true,
    incluirProvCompras: boolean = true
): Promise<TListagemComTotalFuxoCaixaDetalhado | Error> => {
    try {
        const params = new URLSearchParams();

        

        if (incluirProvReceber) params.append('incluirProvReceber', '1');
        if (incluirProvComercial) params.append('incluirProvComercial', '1');
        if (incluirProvPagar) params.append('incluirProvPagar', '1');
        if (incluirProvCompras) params.append('incluirProvCompras', '1');

        

        // Construção da URL com path params e query params
        const requestUrl = `/financeiro/fluxocaixadet/${encodeURIComponent(banco)}/${encodeURIComponent(empresa)}/${dataInicio}/${dataFim}?${params.toString()}`;

        
        
        const { data } = await Api.get(requestUrl);
        
        if (data) {
            return data;
        }

        return new Error('Erro ao listar os registros.');

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

export const FluxoCaixaDetalhadoService = {
    getAllWithoutPagination
};
