import React, { useState } from "react";
import {
  Grid,
  Typography,
  Card,
  Box,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

interface FiltroFluxoDeCaixaProps {
  banco: string[];
  onBancoChange: (banco: string[]) => void;
  selectedYear: number;
  onYearChange: (year: number) => void;
  selectedMonths: number[];
  onMonthChange: (months: number[]) => void;
  onApplyFilter: () => void;
  incluirProvReceber: boolean;
  incluirProvComercial: boolean;
  incluirProvPagar: boolean;
  incluirProvCompras: boolean;
  onToggleProvReceber: () => void;
  onToggleProvComercial: () => void;
  onToggleProvPagar: () => void;
  onToggleProvCompras: () => void;
  empresa: string;
  onEmpresaChange: (empresa: string) => void;
}

const bancosPorEmpresa: Record<"C&S MATRIZ" | "C&S FILIAL", string[]> = {
  "C&S MATRIZ": [
    "BANCO DO ESTADO DO PARA-323257",
    "BANCO DO BRASIL-122404",
    "CAIXA ECONOMICA FEDERAL-903165",
    "MENTORE BANK-4250000010",
    "BANCO ITAU S.A-98694",
    "BANCO DO BRADESCO-214698",
    "APLICAÇÃO BB-122404",
    "XP INVESTIMENTOS-1100000",
    "APLICAÇÃO CAIXA ECONOMICA-903165",
    "APLICAÇÃO BRADESCO-214698",
    "CAIXA ECONOMICA FEDERAL-215",
    "BANCO DA AMAZONIA-71586",
    "NAO IDENTIFICADO",
    "MENTORE BANK-12356",
    "APLICAÇÕES ITAU-98694",
    "APLICAÇÃO BANPARA-323257",
  ],
  "C&S FILIAL": [
    "BANCO DO BRASIL-26798",
    "MENTORE BANK-4250000009",
    "APLICAÇÃO BANCO DO BRASIL-26786",
    "MENTORE BANK-12247",
    "VR MARKETPLACE-161286",
    "BANCO DO BRASIL - MATRIZ-122404",
  ],
};

const marks = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Fev" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Abr" },
  { value: 5, label: "Mai" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Ago" },
  { value: 9, label: "Set" },
  { value: 10, label: "Out" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dez" },
];

const currentYear = new Date().getFullYear();
const nextYear = currentYear + 1;

export const FiltroFluxoDeCaixa: React.FC<FiltroFluxoDeCaixaProps> = ({
  banco,
  onBancoChange,
  selectedYear,
  onYearChange,
  selectedMonths,
  onMonthChange,
  onApplyFilter,
  incluirProvReceber,
  incluirProvComercial,
  incluirProvPagar,
  incluirProvCompras,
  onToggleProvReceber,
  onToggleProvComercial,
  onToggleProvPagar,
  onToggleProvCompras,
  empresa,
  onEmpresaChange,
}) => {
  const [alignment, setAlignment] = useState<number>(selectedYear);
  const [selectAll, setSelectAll] = useState(false);

  const handleYearChange = (
    event: React.MouseEvent<HTMLElement>,
    newYear: number | null
  ) => {
    if (newYear !== null) {
      setAlignment(newYear);
      onYearChange(newYear);
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      onBancoChange(bancosPorEmpresa[empresa as "C&S MATRIZ" | "C&S FILIAL"]);
    } else {
      onBancoChange([]);
    }
  };

  const handleBancoChange = (event: SelectChangeEvent<string[]>) => {
    onBancoChange(event.target.value as string[]);
  };

  return (
    <Grid container spacing={0.3} marginLeft={3}>
      <Grid item xs={12}>
        <Typography variant="h6">FILTROS</Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: 1 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} marginBottom={1}>
        <Card
          sx={{
            padding: 2,
            backgroundColor: "#f5f9ff",
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
            border: "1px solid #ddd",
          }}
        >
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            GLOBAL
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Grid container spacing={2}>
            {/* Filtro de Empresa */}
            <Grid item xs={12} md={2} mb={1}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="empresa-label">Empresa</InputLabel>
                  <Select
                    labelId="empresa-label"
                    value={empresa}
                    onChange={(event) => onEmpresaChange(event.target.value)}
                    label="Empresa"
                  >
                    <MenuItem value="C&S MATRIZ">C&S MATRIZ</MenuItem>
                    <MenuItem value="C&S FILIAL">C&S FILIAL</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Filtro de Banco */}
            <Grid item xs={12} md={5} mb={1}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="banco-label">Banco</InputLabel>
                  <Select
                    labelId="banco-label"
                    value={banco}
                    onChange={handleBancoChange}
                    label="Banco"
                    multiple
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {(
                      bancosPorEmpresa[
                        empresa as "C&S MATRIZ" | "C&S FILIAL"
                      ] || []
                    ).map((bancoItem) => (
                      <MenuItem
                        key={bancoItem}
                        value={bancoItem}
                        sx={{
                          backgroundColor: banco.includes(bancoItem)
                            ? "rgba(122, 194, 230, 0.3)"
                            : "inherit",
                          color: banco.includes(bancoItem)
                            ? "#f05028"
                            : "inherit",
                        }}
                      >
                        {bancoItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                }
                label="Selecionar Todos"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* Filtro de Ano */}
            <Grid item xs={12} md={1.5}>
              <Box>
                <Typography variant="subtitle2">Ano</Typography>
                <ToggleButtonGroup
                  value={alignment}
                  onChange={handleYearChange}
                  color="primary"
                  exclusive
                  aria-label="Year selection"
                >
                  <ToggleButton value={currentYear}>{currentYear}</ToggleButton>
                  <ToggleButton value={nextYear}>{nextYear}</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>

            {/* Filtro de Período (Mês) */}
            <Grid item xs={12} md={10.5}>
              <Box>
                <Typography variant="subtitle2">Período</Typography>
                <Slider
                  value={selectedMonths}
                  onChange={(event, newValue) =>
                    onMonthChange(newValue as number[])
                  }
                  valueLabelDisplay="auto"
                  min={1}
                  max={12}
                  marks={marks}
                  aria-labelledby="month-slider"
                />
              </Box>
            </Grid>
          </Grid>

          {/* Botão de Atualizar */}
          <Grid item xs={12} sx={{ textAlign: "start", marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={onApplyFilter}>
              ATUALIZAR
            </Button>
          </Grid>
        </Card>
      </Grid>

      {/* Seção de Provisões */}
      <Grid item xs={12} md={12} marginBottom={1}>
        <Card
          sx={{
            padding: 2,
            backgroundColor: "#f5f9ff",
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
            border: "1px solid #ddd",
          }}
        >
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            PROVISÕES
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <FormControlLabel
            control={
              <Switch
                checked={incluirProvReceber}
                onChange={onToggleProvReceber}
                color="primary"
              />
            }
            label="Incluir Provisão a Receber"
          />
          <FormControlLabel
            control={
              <Switch
                checked={incluirProvComercial}
                onChange={onToggleProvComercial}
                color="primary"
              />
            }
            label="Incluir Provisão Comercial"
          />
          <FormControlLabel
            control={
              <Switch
                checked={incluirProvPagar}
                onChange={onToggleProvPagar}
                color="primary"
              />
            }
            label="Incluir Provisão a Pagar"
          />
          <FormControlLabel
            control={
              <Switch
                checked={incluirProvCompras}
                onChange={onToggleProvCompras}
                color="primary"
              />
            }
            label="Incluir Provisão de Compras"
          />
        </Card>
      </Grid>
    </Grid>
  );
};
