import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  IPostosProtheus,
  PostosProtheusService,
} from "../../../../shared/services/api/comercial/clientes/PostosServices";
import { DataGrid, GridCloseIcon, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface PostosModalProps {
  open: boolean;
  onClose: () => void;
  idexternodocliente: string; // Ajustado para number
  nomeCliente?: string;
}

export const PostosModal: React.FC<PostosModalProps> = ({
  open,
  onClose,
  idexternodocliente,
  nomeCliente,
}) => {
  const [postosData, setPostosData] = useState<IPostosProtheus[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      PostosProtheusService.getAllWithoutPagination()
        .then((result) => {
          if (result instanceof Error) {
            toast.error(result.message);
            return;
          }

          // Filtra os postos relacionados ao cliente selecionado
          const postosFiltrados = result
            .filter(
              (posto) => posto.idexternodocliente === idexternodocliente // Comparação como número
            )
            .map((posto) => ({
              ...posto,
              cidadeCodetd: `${posto.cidade} - ${posto.codetd}`, // Cria uma nova chave 'cidadeCodetd' com os valores combinados
            }));

          setPostosData(postosFiltrados);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao carregar os dados dos postos:", error);
          toast.error("Erro ao carregar os dados dos postos.");
          setLoading(false);
        });
    }
  }, [open, idexternodocliente]);

  // Definir as colunas do DataGrid para o modal de postos
  const columns: GridColDef[] = [
    { field: "ccusto", headerName: "Centro de Custo", flex: 1 },
    { field: "cliente", headerName: "Descrição", flex: 1 },
    { field: "posto", headerName: "Posto", flex: 1 },
    { field: "cidadeCodetd", headerName: "Cidade", flex: 1 },
    {
      field: "vagas",
      headerName: "Total de Vagas",
      flex: 1,
      align: "right",
    },
  ];
  const exportToExcel = () => {
    // Mapeia os dados apenas para as colunas que você quer exportar
    const exportData = postosData.map((row) => ({
      "Centro de Custo": row.ccusto,
      Descrição: row.cliente,
      Posto: row.posto,
      Cidade: row.cidade,
      Estado: row.codetd,
      "Total de Vagas": row.vagas,
    }));

    // Converte para XLSX
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Postos");

    // Salva o arquivo XLSX
    XLSX.writeFile(workbook, "postos_cliente.xlsx");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "80%",
          height: "70%",
          p: 4,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          textAlign: "center",
          margin: "5% auto",
          marginBottom: "50px",
        }}
      >
        <AppBar
          position="static"
          sx={{
            background: "linear-gradient(90deg, #505590,#4a86c9,#2fbbfa);",
            boxShadow: "none", // Remove a sombra padrão do AppBar
            borderRadius: "8px 8px 0 0", // Bordas arredondadas no topo
          }}
        >
          <Toolbar sx={{ minHeight: "56px", padding: "0 16px" }}>
            {" "}
            {/* Reduz a altura do AppBar e ajusta o padding */}
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, textAlign: "left", color: "#fff" }}
            >
              POSTOS - {nomeCliente}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={exportToExcel}>
              <FileDownloadIcon sx={{ color: "#fff" }} />{" "}
              {/* Ícone de download em branco */}
            </IconButton>
            <IconButton edge="end" color="inherit" onClick={onClose}>
              <GridCloseIcon sx={{ color: "#fff" }} />{" "}
              {/* Ícone de fechar em branco */}
            </IconButton>
          </Toolbar>
        </AppBar>

        {loading ? (
          <LinearProgress variant="indeterminate" />
        ) : (
          <div
            style={{
              height: "85%",
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <DataGrid
              rows={postosData}
              columns={columns}
              getRowId={(row) => row.idposto} // ID único
            />
          </div>
        )}
      </Box>
    </Modal>
  );
};
