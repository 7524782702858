import { useEffect, useState } from "react";
import { Grid, Box, Skeleton } from "@mui/material";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import { toast } from "react-toastify";
import { PostosProtheusService } from "../../../shared/services/api/comercial/clientes/PostosServices";
import { LayoutBasePagina } from "../../../shared/layouts";
import { DashboardCardOperacional } from "./components/DashboardCard";
import { ClienteModal } from "./components/ClienteModal";
import { InformacoesComerciais } from "./components/InformacoesComerciais";

export const AuditoriaEfetivoPage: React.FC = () => {
  const [clientes, setClientes] = useState<number | null>(null);
  const [contratos, setContratos] = useState<number | null>(null);
  const [postos, setPostos] = useState<number | null>(null);
  const [vagas, setVagas] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [pontosDeAtuacao, setPontosDeAtuacao] = useState<
    {
      rua: string;
      numero: string;
      bairro: string;
      codmunicipio: string;
      cidade: string;
      pais: string;
      codetd: string;
    }[]
  >([]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    // Carregar os dados para os cards e o mapa
    PostosProtheusService.getAllWithoutPagination()
      .then((result) => {
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        }

        // Calcula os totais necessários
        const uniqueClientes = new Set(result.map((posto) => posto.codcliente))
          .size;
        const uniqueContratos = new Set(result.map((posto) => posto.idcontrato))
          .size;
        const totalPostos = result.length;
        const totalVagas = result.reduce((acc, posto) => acc + posto.vagas, 0);

        setClientes(uniqueClientes);
        setContratos(uniqueContratos);
        setPostos(totalPostos);
        setVagas(totalVagas);

        // Prepara os pontos de atuação para o mapa
        const pontos = result.map((posto) => ({
          rua: posto.rua,
          numero: posto.numero,
          bairro: posto.bairro,
          codmunicipio: posto.codmunicipio,
          cidade: posto.cidade,
          pais: posto.pais,
          codetd: posto.codetd,
        }));
        setPontosDeAtuacao(pontos);
      })
      .catch((error) => {
        console.error("Erro ao carregar os dados da auditoria:", error);
        toast.error("Erro ao carregar os dados da auditoria.");
      });
  }, []);

  return (
    <LayoutBasePagina titulo="AUDITORIA DE EFETIVO">
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            {clientes === null ? (
              <Skeleton variant="rectangular" width="100%" height={118} />
            ) : (
              <DashboardCardOperacional
                title="Clientes"
                value={clientes}
                Icon={BusinessIcon}
                gradientColors={["#42a5f5", "#478ed1"]}
                footerText="Clientes"
                onClick={handleOpenModal}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {contratos === null ? (
              <Skeleton variant="rectangular" width="100%" height={118} />
            ) : (
              <DashboardCardOperacional
                title="Contratos"
                value={contratos}
                Icon={AutoAwesomeMotionIcon}
                gradientColors={["#f48fb1", "#f06292"]}
                footerText="Contratos"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {postos === null ? (
              <Skeleton variant="rectangular" width="100%" height={118} />
            ) : (
              <DashboardCardOperacional
                title="Postos"
                value={postos}
                Icon={PeopleIcon}
                gradientColors={["#ffcc80", "#ffa726"]}
                footerText="Postos"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {vagas === null ? (
              <Skeleton variant="rectangular" width="100%" height={118} />
            ) : (
              <DashboardCardOperacional
                title="Vagas"
                value={vagas}
                Icon={MonetizationOnIcon}
                gradientColors={["#4dd0e1", "#26c6da"]}
                footerText="Vagas"
              />
            )}
          </Grid>
        </Grid>

        {/* Modal para exibir a lista de todos os clientes */}
        <ClienteModal open={modalOpen} onClose={handleCloseModal} />

        {/* Adicionando o componente de Informações Comerciais */}
        <Box mt={4}>
          <InformacoesComerciais />
        </Box>
      </Box>
    </LayoutBasePagina>
  );
};
