import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import { Login, MenuLateral } from "./shared/components";
import { AuthProvider, DrawerProvider } from "./shared/contexts";
import './shared/form/TraducoesYup'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const App = () => {
  return (
    <AuthProvider >
      <Login>
        <BrowserRouter>
          <DrawerProvider>
            
            <MenuLateral>
              <AppRoutes/>
            </MenuLateral>
          
          </DrawerProvider>
        </BrowserRouter>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </Login>
    </AuthProvider>
  );
}