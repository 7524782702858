import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IListagemFluxoCaixaResumido {
    E5_DATA: string; // Data da movimentação
    MovEntrada: number; // Total de entradas no dia
    MovSaida: number; // Total de saídas no dia
    ProvComercial: number; // Provisão comercial
    ProvReceber: number; // Provisão a receber
    ProvPagar: number; // Provisão a pagar
    ProvCompras: number; // Provisão de compras
    SaldoDia: number; // Saldo do dia acumulado
}

export interface IDetalheFluxoCaixaResumido {
    E5_DATA: string; // Data da movimentação
    MovEntrada: number; // Total de entradas no dia
    MovSaida: number; // Total de saídas no dia
    ProvComercial: number; // Provisão comercial
    ProvReceber: number; // Provisão a receber
    ProvPagar: number; // Provisão a pagar
    ProvCompras: number; // Provisão de compras
    SaldoDia: number; // Saldo do dia acumulado
}

type TListagemComTotalFuxoCaixaResumido = {
    data: IListagemFluxoCaixaResumido[];
    totalCount: number;
    totalValor: number;
};

const getAllWithoutPagination = async (
    banco: string,
    empresa: string,
    dataInicio: string,
    dataFim: string,
    incluirProvReceber: boolean = true,
    incluirProvComercial: boolean = true,
    incluirProvPagar: boolean = true,
    incluirProvCompras: boolean = true
): Promise<TListagemComTotalFuxoCaixaResumido | Error> => {
    try {
        // Adiciona apenas os parâmetros de provisão que estão ativos
        const params = new URLSearchParams();

        if (incluirProvReceber) params.append('incluirProvReceber', '1');
        if (incluirProvComercial) params.append('incluirProvComercial', '1');
        if (incluirProvPagar) params.append('incluirProvPagar', '1');
        if (incluirProvCompras) params.append('incluirProvCompras', '1');



        // Construção da URL com path params e query params
        const requestUrl = `/financeiro/fluxocaixares/${encodeURIComponent(banco)}/${encodeURIComponent(empresa)}/${dataInicio}/${dataFim}?${params.toString()}`;
        
       
        const { data } = await Api.get(requestUrl);

        if (data) {
            return data;
        }

        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

  
  

export const FluxoCaixaService = {
    getAllWithoutPagination
};
