import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";

interface CardProps {
  title: string;
  value: number;
  footerText: string;
  Icon: React.ElementType;
  gradientColors: string[];
}

interface CardsProps {
  cardsData: CardProps[];
}

export const FluxoDeCaixaCards: React.FC<CardsProps> = ({ cardsData }) => {
  return (
    <Grid container spacing={2} marginLeft={1}>
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={6} md={2} key={index}>
          <Card
            sx={{
              backgroundColor: "#f5f7fa",
              color: "#2c3e50",
              borderRadius: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
              border: "0.1px solid #ddd",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      marginBottom: "0.3rem",
                    }}
                  >
                    {card.value.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.70rem",
                      color: "#7f8c8d",
                    }}
                  >
                    {card.footerText}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    background: `linear-gradient(135deg, ${card.gradientColors[0]}, ${card.gradientColors[1]})`,
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                  }}
                >
                  <card.Icon sx={{ fontSize: 24 }} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
