import { Box, Button, Divider, Icon, Paper,Skeleton,Typography,useMediaQuery,useTheme } from "@mui/material"

interface IFerramentasDeDetalheProps{
    textoBotaoNovo?: string;

    mostrarBotaoNovo? : boolean;
    mostrarBotaoVoltar? : boolean;
    mostrarBotaoApagar? : boolean;
    mostrarBotaoSalvar? : boolean;
    mostrarBotaoSalvarFechar? : boolean;

    mostrarBotaoNovoCarregando? : boolean;
    mostrarBotaoVoltarCarregando? : boolean;
    mostrarBotaoApagarCarregando? : boolean;
    mostrarBotaoSalvarCarregando? : boolean;
    mostrarBotaoSalvarFecharCarregando? : boolean;

    aoClicarEmNovo? : () => void;
    aoClicarEmVoltar? : () => void;
    aoClicarEmApagar? : () => void;
    aoClicarEmSalvar? : () => void;
    aoClicarEmSalvarFechar? : () => void;
}

export const FerramentasDeDetalhe : React.FC<IFerramentasDeDetalheProps> =({
   
    textoBotaoNovo = 'Novo',
    mostrarBotaoNovo = true,
    mostrarBotaoVoltar = true,
    mostrarBotaoApagar = true,
    mostrarBotaoSalvar = true,
    mostrarBotaoSalvarFechar = false,

    mostrarBotaoNovoCarregando = false,
    mostrarBotaoVoltarCarregando = false,
    mostrarBotaoApagarCarregando = false,
    mostrarBotaoSalvarCarregando = false,
    mostrarBotaoSalvarFecharCarregando = false,

    aoClicarEmNovo,
    aoClicarEmVoltar,
    aoClicarEmApagar,
    aoClicarEmSalvar,
    aoClicarEmSalvarFechar

})=>{
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))

    return(
        <Box
            height={theme.spacing(5)}
            marginX={1}
            padding={2}
            paddingX={1}
            display="flex"
            gap={1}
            alignItems="center"
            component={Paper}
        >
            {(mostrarBotaoSalvar && !mostrarBotaoSalvarCarregando ) && (
            <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={aoClicarEmSalvar}
                startIcon={<Icon>save</Icon>}
                >
                <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    Salvar
                </Typography>
            </Button>)}

           {mostrarBotaoSalvarCarregando && (
             <Skeleton width={100} height={60}/>
           )}

            {(mostrarBotaoSalvarFechar && !mostrarBotaoSalvarFecharCarregando && !mdDown && !smDown) && (
            <Button
                color="primary"
                disableElevation
                variant="outlined"
                onClick={aoClicarEmSalvarFechar}
                startIcon={<Icon>save</Icon>}
                >                    
                <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    Salvar e fechar
                </Typography>    
            </Button>)}

           {(mostrarBotaoSalvarFecharCarregando && !mdDown && !smDown) && (
             <Skeleton width={200} height={60}/>
           )}

            {(mostrarBotaoApagar && !mostrarBotaoApagarCarregando) && ( 
            <Button
                color="primary"
                disableElevation
                variant="outlined"
                onClick={aoClicarEmApagar}
                startIcon={<Icon>delete</Icon>}
                >
                <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    Apagar
                </Typography>
            </Button>)}

           {mostrarBotaoApagarCarregando && (
             <Skeleton width={100} height={60}/>
           )}

            {(mostrarBotaoNovo && !mostrarBotaoNovoCarregando && !smDown) && ( 
            <Button
                color="primary"
                disableElevation
                variant="outlined"
                onClick={aoClicarEmNovo}
                startIcon={<Icon>add</Icon>}
                >
                <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {textoBotaoNovo}
                </Typography>    
            </Button> )}

           {(mostrarBotaoNovoCarregando && !smDown) && (
             <Skeleton width={100} height={60}/>
           )}

            {(mostrarBotaoVoltar) && 
            (mostrarBotaoApagar || mostrarBotaoNovo || mostrarBotaoSalvar || mostrarBotaoSalvarFechar)
            &&(
                <Divider variant="middle" orientation="vertical" />
            )}

            {(mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando) && ( 
            <Button
                color="primary"
                disableElevation
                variant="outlined"
                onClick={aoClicarEmVoltar}
                startIcon={<Icon>arrow_back</Icon>}
                >

                <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    Voltar
                </Typography>    

            </Button>)}

           {mostrarBotaoVoltarCarregando && (
             <Skeleton width={100} height={60}/>
           )}

        </Box>
    )
}