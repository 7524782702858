import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IListagemContasPagar {
    R_E_C_N_O_: number;
    E2_FILIAL: string;
    E2_EMISSAO: Date;
    E2_NUM: string;
    E2_PARCELA: string;
    E2_TIPO: string;
    E2_FORNECE: string;
    E2_NOMFOR: string;
    E2_VALOR: number;
    E2_ORIGEM: string;
    E2_HIST: string;
    E2_STATUS: string;
    E2_STATLIB: string;
    E2_SITUACAO: string;
    EMPRESA: string;
    E2_VENCTO: string;
    E2_VENCTOFOR: string;
}

export interface IDetalheContasPagar {
    R_E_C_N_O_: number;
    E2_FILIAL: string;
    E2_EMISSAO: Date;
    E2_NUM: string;
    E2_PARCELA: string;
    E2_TIPO: string;
    E2_FORNECE: string;
    E2_NOMFOR: string;
    E2_VALOR: number;
    E2_ORIGEM: string;
    E2_HIST: string;
    E2_STATUS: string;
    E2_STATLIB: string;
    E2_SITUACAO: string;
    EMPRESA: string;
    E2_VENCTO: string;
    E2_VENCTOFOR: string;
}

type TListagemComTotalContasPagar = {
    data: IListagemContasPagar[];
    totalCount: number;
    totalValor: number;
}

const getAll = async(page = 1, filter = '',dataInicio?: string, dataFim?: string, empresas?: number[], status?: number[]): Promise<TListagemComTotalContasPagar | Error> => {

    try {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('limit', Enviroment.LIMITE_DE_LINHAS.toString());
        if (filter) params.append('num', filter);
        if (dataInicio) params.append('dataInicio', dataInicio);
        if (dataFim) params.append('dataFim', dataFim);
        if (empresas && empresas.length > 0) params.append('empresas', empresas.join(','));
        if (status && status.length > 0) params.append('status', status.join(','));

               // Log da URL da requisição
               const requestUrl = `/financeiro/cpagar?${params.toString()}`;
               //console.log(`Requisição GET para: ${requestUrl}`);

        const { data, headers  } = await Api.get(`/financeiro/cpagar?${params.toString()}`);
        

        if (data) {
            console.log(headers)
            return {
                data,
                totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
                totalValor: Number(headers['x-total-valor'] || Enviroment.TOTAL)
                
            }
        }


        return new Error('Erro ao listar os registros.');

    } catch (error) {
        console.error(error)
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};

// Serviço para busca sem paginação
const getAllWithoutPagination = async(
    filter = '',
    dataInicio?: string,
    dataFim?: string,
    empresas?: number[],
    status?: number[]
): Promise<IListagemContasPagar[] | Error> => {
    try {
        const params = new URLSearchParams();
        if (filter) params.append('num', filter);
        if (dataInicio) params.append('dataInicio', dataInicio);
        if (dataFim) params.append('dataFim', dataFim);
        if (empresas && empresas.length > 0) params.append('empresas', empresas.join(','));
        if (status && status.length > 0) params.append('status', status.join(','));

        const requestUrl = `/financeiro/cpagar?${params.toString()}&semPaginacao=true`;

        const { data } = await Api.get(requestUrl);

        if (data) {
            return data;
        }

        return new Error('Erro ao listar os registros.');

    } catch (error) {
        console.error(error);
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};

const getById = async(id: string): Promise<IDetalheContasPagar | Error> => {
    try {
        const { data } = await Api.get(`/financeiro/cpagar/${id}`);

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');

    } catch (error) {
        console.error(error)
        return new Error((error as {message: string}).message || 'Erro ao consultar o registro.');
    }
}


const generateReport = async (filter = '', dataInicio?: string, dataFim?: string, empresas?: number[], status?: number[]): Promise<void | Error> => {
    try {
        const response = await Api.post('/financeiro/cpagar/relatorio', {
            filter,
            dataInicio,
            dataFim,
            empresas,
            status
        });

        if (response.status !== 200) {
            return new Error('Erro ao solicitar a geração do relatório.');
        }
    } catch (error) {
        console.error(error);
        return new Error('Erro ao solicitar a geração do relatório.');
    }
}

export const ContasPagarService = {
    getAll,
    getById,
    generateReport,
    getAllWithoutPagination
};
