import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  ContasReceberService,
  IDetalheContasReceber,
} from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import {
  AppBar,
  Box,
  Skeleton,
  Toolbar,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as XLSX from "xlsx"; // Adicionando XLSX para exportação
import DownloadIcon from "@mui/icons-material/Download";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Height } from "@mui/icons-material";

interface ContasReceberDataGridProps {
  empresasSelecionadas: string[];
}

interface ContasReceberRow {
  cliente: string;
  totalBruto: number;
  totalINSS: number;
  totalISS: number;
  totalIRRF: number;
  totalCSLL: number;
  totalPIS: number;
  totalCOFINS: number;
  totalLiquido: number;
  status: string;
  detalhes?: IDetalheContasReceber[]; // Incluindo os detalhes de títulos
}

export const ContasReceberDataGrid: React.FC<ContasReceberDataGridProps> = ({
  empresasSelecionadas,
}) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<ContasReceberRow[]>([]);
  const [vencimentoInicio, setVencimentoInicio] = useState<dayjs.Dayjs | null>(
    null
  );
  const [vencimentoFim, setVencimentoFim] = useState<dayjs.Dayjs | null>(null);
  const [selectedCliente, setSelectedCliente] = useState<string | null>(null); // Estado para o cliente selecionado

  // Estado para armazenar os totais
  const [totals, setTotals] = useState({
    totalBruto: 0,
    totalINSS: 0,
    totalISS: 0,
    totalIRRF: 0,
    totalCSLL: 0,
    totalPIS: 0,
    totalCOFINS: 0,
    totalLiquido: 0,
  });

  const formatToCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  useEffect(() => {
    const fetchContasReceber = async () => {
      setLoading(true);
      try {
        const response = await ContasReceberService.getAllWithoutPagination();

        if (response instanceof Error) {
          console.error(response.message);
          return;
        }

        const vencimentoInicioFormatted = vencimentoInicio
          ? vencimentoInicio.format("YYYYMMDD")
          : null;
        const vencimentoFimFormatted = vencimentoFim
          ? vencimentoFim.format("YYYYMMDD")
          : null;

        const contasReceberFiltradas = response.filter(
          (item: IDetalheContasReceber) => {
            const status = item.STATUS?.trim().toUpperCase();
            const situacao = item.SITUACAO?.trim().toUpperCase();
            const vencimento = dayjs(item.venc2).format("YYYYMMDD");

            return (
              (status === "VENCIDO" || status === "EM DIAS") &&
              situacao === "ABERTO" &&
              empresasSelecionadas.includes(item.EMPRESA) &&
              (!vencimentoInicioFormatted ||
                vencimento >= vencimentoInicioFormatted) &&
              (!vencimentoFimFormatted || vencimento <= vencimentoFimFormatted)
            );
          }
        );

        const groupedByCliente = contasReceberFiltradas.reduce(
          (acc: Record<string, ContasReceberRow>, curr) => {
            const cliente = curr.A1_NOME.trim();
            if (!acc[cliente]) {
              acc[cliente] = {
                cliente,
                totalBruto: 0,
                totalINSS: 0,
                totalISS: 0,
                totalIRRF: 0,
                totalCSLL: 0,
                totalPIS: 0,
                totalCOFINS: 0,
                totalLiquido: 0,
                status: curr.STATUS || "",
                detalhes: [], // Adicionar array de detalhes
              };
            }

            acc[cliente].totalBruto += curr.BRUTO || 0;
            acc[cliente].totalINSS += curr.F2_VALINSS || 0;
            acc[cliente].totalISS += curr.F2_VALISS || 0;
            acc[cliente].totalIRRF += curr.F2_VALIRRF || 0;
            acc[cliente].totalCSLL += curr.F2_VALCSLL || 0;
            acc[cliente].totalPIS += curr.F2_VALPIS || 0;
            acc[cliente].totalCOFINS += curr.F2_VALCOFI || 0;
            acc[cliente].totalLiquido += curr.LIQUIDO || 0;

            acc[cliente].detalhes?.push(curr); // Adicionar os detalhes à linha
            return acc;
          },
          {}
        );

        const totalsCalculated = contasReceberFiltradas.reduce(
          (totals, curr) => {
            totals.totalBruto += curr.BRUTO || 0;
            totals.totalINSS += curr.F2_VALINSS || 0;
            totals.totalISS += curr.F2_VALISS || 0;
            totals.totalIRRF += curr.F2_VALIRRF || 0;
            totals.totalCSLL += curr.F2_VALCSLL || 0;
            totals.totalPIS += curr.F2_VALPIS || 0;
            totals.totalCOFINS += curr.F2_VALCOFI || 0;
            totals.totalLiquido += curr.LIQUIDO || 0;
            return totals;
          },
          {
            totalBruto: 0,
            totalINSS: 0,
            totalISS: 0,
            totalIRRF: 0,
            totalCSLL: 0,
            totalPIS: 0,
            totalCOFINS: 0,
            totalLiquido: 0,
          }
        );

        setRows(Object.values(groupedByCliente));
        setTotals(totalsCalculated);
      } catch (error) {
        console.error("Erro ao buscar Contas a Receber:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContasReceber();
  }, [empresasSelecionadas, vencimentoFim]);

  // Função para exportar os detalhes (não agrupados)
  const exportAllDetailsToExcel = () => {
    const allDetalhes = rows.flatMap((row) => row.detalhes || []);

    const worksheet = XLSX.utils.json_to_sheet(
      allDetalhes.map((detalhe) => ({
        Cliente: detalhe.A1_NOME,
        "Centro de Custo": detalhe.C5_XCC,
        Emissão: detalhe.EMISSAO,
        "N. Fiscal": detalhe.E1_NFELETR,
        "Valor Bruto": detalhe.BRUTO,
        INSS: detalhe.F2_VALINSS,
        ISS: detalhe.F2_VALISS,
        IRRF: detalhe.F2_VALIRRF,
        CSLL: detalhe.F2_VALCSLL,
        PIS: detalhe.F2_VALPIS,
        COFINS: detalhe.F2_VALCOFI,
        Líquido: detalhe.LIQUIDO,
        Vencimento: detalhe.VENCIMENTO,
        Tipo: detalhe.E1_TIPO,
        Status: detalhe.STATUS,
        "N. Dias": detalhe.DIAS,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Detalhes_Titulos");
    XLSX.writeFile(workbook, `Detalhes_Titulos.xlsx`);
  };

  // Função para exportar os dados do pai para XLS
  const exportToExcelPai = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clientes");
    XLSX.writeFile(workbook, `clientes_contas_receber.xlsx`);
  };

  // Função para exportar os dados do filho para XLS
  const exportToExcelFilho = () => {
    const clienteDetalhes =
      rows.find((row) => row.cliente === selectedCliente)?.detalhes || [];
    const worksheet = XLSX.utils.json_to_sheet(clienteDetalhes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Detalhes_titulos}`);
    XLSX.writeFile(workbook, `detalhes_${selectedCliente}.xlsx`);
  };

  const columns: GridColDef[] = [
    { field: "cliente", headerName: "Cliente", flex: 2 },
    {
      field: "totalBruto",
      headerName: "Valor Bruto",
      flex: 1,
      align: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalINSS",
      headerName: "INSS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalISS",
      headerName: "ISS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalIRRF",
      headerName: "IRRF",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalCSLL",
      headerName: "CSLL",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalPIS",
      headerName: "PIS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalCOFINS",
      headerName: "COFINS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "totalLiquido",
      headerName: "Líquido",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
  ];

  const detailedColumns: GridColDef[] = [
    { field: "EMISSAO", headerName: "EMISSAO", flex: 1 },
    { field: "E1_NFELETR", headerName: "N. FISCAL", flex: 1 },
    {
      field: "BRUTO",
      headerName: "VALOR BRUTO",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALINSS",
      headerName: "INSS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALISS",
      headerName: "ISS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALIRRF",
      headerName: "IRRF",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALCSLL",
      headerName: "CSLL",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALPIS",
      headerName: "PIS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALCOFI",
      headerName: "COFINS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "LIQUIDO",
      headerName: "LIQUIDO",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    { field: "VENCIMENTO", headerName: "VENCIMENTO", flex: 1 },
    { field: "E1_TIPO", headerName: "TIPO", flex: 1 },
    { field: "STATUS", headerName: "STATUS", flex: 1 },
    { field: "DIAS", headerName: "N. DIAS", flex: 1 },
  ];

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <AppBar
        position="static"
        sx={{
          background: "linear-gradient(45deg,#FE6B8B 30%, #FF8E53 90%)",
          boxShadow: "none",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Toolbar>
          {selectedCliente && (
            <IconButton
              onClick={() => setSelectedCliente(null)} // Voltar para a lista de clientes
              sx={{ color: "white" }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <IconButton sx={{ color: "white" }}>
              <MonetizationOnIcon />
            </IconButton>
            {selectedCliente
              ? `CLIENTE: ${selectedCliente}`
              : "TITULOS A RECEBER"}
          </Typography>
          <IconButton onClick={exportAllDetailsToExcel} sx={{ color: "white" }}>
            <SummarizeIcon />
          </IconButton>
          {!selectedCliente ? (
            <IconButton onClick={exportToExcelPai} sx={{ color: "#fff" }}>
              <DownloadIcon />
            </IconButton>
          ) : (
            <IconButton onClick={exportToExcelFilho} sx={{ color: "#fff" }}>
              <DownloadIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {loading ? (
        <Skeleton sx={{ height: 500, width: "100%" }} />
      ) : selectedCliente ? (
        <>
          {/* Grid de detalhes */}
          <DataGrid
            rows={
              rows.find((row) => row.cliente === selectedCliente)?.detalhes ||
              []
            }
            columns={detailedColumns}
            getRowId={(row) => `${row.ID}`} // ID único de cada título
            autoHeight
            hideFooter
          />
        </>
      ) : (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginTop: 2,
                marginBottom: 1,
                width: "30%",
              }}
            >
              <DatePicker
                label="Vencimento Início"
                value={vencimentoInicio}
                onChange={(newValue) => setVencimentoInicio(newValue)}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      backgroundColor: "white",
                      borderRadius: 1,
                    },
                  },
                }}
              />
              <DatePicker
                label="Vencimento Fim"
                value={vencimentoFim}
                onChange={(newValue) => setVencimentoFim(newValue)}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      backgroundColor: "white",
                      borderRadius: 1,
                    },
                  },
                }}
              />
            </Box>
          </LocalizationProvider>

          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.cliente}
            autoHeight
            onRowDoubleClick={(params: GridRowParams) => {
              const cliente = params.row.cliente;
              setSelectedCliente(cliente); // Definir o cliente selecionado
            }}
          />

          {/* Seção de Totalizadores */}
          <Box sx={{ marginTop: 2, marginBottom: 10 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Resumo Totalizadores
            </Typography>
            <DataGrid
              rows={[
                {
                  id: "totalizadores",
                  BRUTO: totals.totalBruto,
                  INSS: totals.totalINSS,
                  ISS: totals.totalISS,
                  IRRF: totals.totalIRRF,
                  CSLL: totals.totalCSLL,
                  PIS: totals.totalPIS,
                  COFINS: totals.totalCOFINS,
                  LIQUIDO: totals.totalLiquido,
                },
              ]}
              columns={[
                {
                  field: "BRUTO",
                  headerName: "Total Bruto",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "INSS",
                  headerName: "Total INSS",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "ISS",
                  headerName: "Total ISS",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "IRRF",
                  headerName: "Total IRRF",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "CSLL",
                  headerName: "Total CSLL",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "PIS",
                  headerName: "Total PIS",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "COFINS",
                  headerName: "Total COFINS",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
                {
                  field: "LIQUIDO",
                  headerName: "Total Líquido",
                  flex: 1,
                  align: "right",
                  headerAlign: "right",
                  renderCell: (params) => (
                    <Box sx={{ textAlign: "right", width: "100%" }}>
                      {formatToCurrency(params.value)}
                    </Box>
                  ),
                },
              ]}
              hideFooter
              autoHeight
            />
          </Box>
        </>
      )}
    </Box>
  );
};
