// SituacaoCell.tsx
import React from 'react';
import { TableCell, Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoopIcon from '@mui/icons-material/Loop';
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface SituacaoCellProps {
  situacao: string;
}

const SituacaoCell: React.FC<SituacaoCellProps> = ({ situacao }) => {
  const getSx = (situation: string) => {
    switch (situation) {
      case 'Liberado':
        return {
          color: '#03ad20', // Neon green
          borderColor: '#03ad20',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.75rem',
          padding: '2px 8px',
          border: '1px solid',
          borderRadius: '4px',
          backgroundColor: 'rgba(57, 255, 20, 0.1)', // Slightly neon background
        };
      case 'Aguardando Liberação':
        return {
          color: '#FF5733', // Neon orange
          borderColor: '#FF5733',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.75rem',
          padding: '2px 8px',
          border: '1px solid',
          borderRadius: '4px',
          backgroundColor: 'rgba(255, 87, 51, 0.1)', // Slightly neon background
        };
      default:
        return {};
    }
  };

  const getIcon = (situation: string) => {
    switch (situation) {
      case 'Liberado':
        return <CheckCircleOutlineIcon fontSize="small" sx={{ color: '#03ad20' }} />;
      case 'Aguardando Liberação':
        return <LoopIcon fontSize="small" sx={{ color: '#FF5733' }} />;
      default:
        return null;
    }
  };

  return (
    <TableCell width={10}>
      <Box sx={getSx(situacao)}>
        {getIcon(situacao)}
        <Typography variant="body2" component="span" sx={{ marginLeft: '4px' }}>
          {situacao}
        </Typography>
      </Box>
    </TableCell>
  );
};

export default SituacaoCell;
