import React, { useEffect, useState } from "react";
import { DashboardCard } from "../../../../shared/components/dashboads/DashCards";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { PostosProtheusService } from "../../../../shared/services/api/comercial/clientes/PostosServices";
import dayjs from "dayjs";

interface ValorTotalRecorrenteProps {
  empresasSelecionadas: string[];
  setTotalProvisao: (value: number) => void;
}

export const ValorTotalRecorrente: React.FC<ValorTotalRecorrenteProps> = ({
  empresasSelecionadas,
  setTotalProvisao,
}) => {
  const [loading, setLoading] = useState(false);
  const [totalProvisao, setLocalTotalProvisao] = useState<number>(0);

  useEffect(() => {
    const fetchTotalRecorrente = async () => {
      setLoading(true);
      try {
        const response = await PostosProtheusService.getAllWithoutPagination();
        if (response instanceof Error) {
          console.error(response.message);
          return;
        }

        const currentMonthStart = dayjs().startOf("month"); // Início do mês atual
        const currentMonthEnd = dayjs().endOf("month"); // Fim do mês atual

        // Filtrar registros pelas empresas selecionadas e verificar se os postos estão ativos durante o mês atual
        const registrosFiltrados = response.filter((item: any) => {
          const inicioDate = dayjs(item.postoinicio, "YYYYMMDD");
          const fimDate = item.postofim
            ? dayjs(item.postofim, "YYYYMMDD")
            : null;

          // Verificar se o posto está ativo durante o mês atual, inclusive no primeiro dia do mês de término
          return (
            empresasSelecionadas.includes(item.empresa) &&
            (inicioDate.isBefore(currentMonthEnd) ||
              inicioDate.isSame(currentMonthEnd)) &&
            (!fimDate ||
              fimDate.isAfter(currentMonthStart) ||
              fimDate.isSame(currentMonthStart))
          );
        });

        // Calcular o valor integral para os postos ativos durante o mês atual (sem proporção)
        const totalPagar = registrosFiltrados.reduce(
          (acc: number, curr: any) => acc + (curr.valortotal || 0),
          0
        );

        setLocalTotalProvisao(totalPagar);
        setTotalProvisao(totalPagar); // Atualiza o estado totalProvisao no Dashboard
      } catch (error) {
        console.error("Erro ao buscar o valor recorrente:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalRecorrente();
  }, [empresasSelecionadas]);

  return (
    <DashboardCard
      title="Provisão do Mês"
      value={loading ? "Carregando..." : totalProvisao}
      footerText="Provisão comercial"
      gradientColors={["#17b2dd", "#1b8dfa"]}
      Icon={MonetizationOnIcon}
      isCurrency={true}
    />
  );
};
