import { Search } from "@mui/icons-material";
import {
  Button,
  Icon,
  InputAdornment,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/material";
import { Enviroment } from "../../environment";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import customParseFormat from "dayjs/plugin/customParseFormat"; // Importa o plugin de formatação
import { MultipleSelectChip } from "./MultipleSelectChip"; // Importa o componente MultipleSelectChip
import { SelectChangeEvent } from "@mui/material/Select"; // Importa SelectChangeEvent
import { StatusCheckboxes } from "./StatusCheckboxes";
import { GenerateReportButton } from "./GenerateReportButton";
import { GenerateExcelButton } from "./GenerateExcelButton copy";

dayjs.extend(customParseFormat);

interface IOption {
  id: number;
  label: string;
}

interface IFerramentasDeFiltros {
  title?: string;
  listaMultiSelect?: IOption[];
  listaCheckBox?: IOption[];
  selectedOptions?: number[];
  checkBoxOptions?: number[];
  handleChangeSelect?: (event: SelectChangeEvent<number[]>) => void; // Permite tanto eventos de seleção quanto arrays de números
  handleChangeCheck?: (selected: number[]) => void; // Permite tanto eventos de seleção quanto arrays de números
  mostraCheckBox?: boolean;
  mostraMultiSelect?: boolean;
  mostrarGerarRelatorio?: boolean;
  aoClicarEmGerarRelatorio?: () => void;
  mostrarGerarExcel?: boolean;
  aoClicarEmGerarExcel?: () => void;
}

export const FerramentasDeFiltros: React.FC<IFerramentasDeFiltros> = ({
  title,
  listaMultiSelect,
  listaCheckBox,
  selectedOptions,
  checkBoxOptions,
  handleChangeSelect,
  handleChangeCheck,
  mostraCheckBox = false,
  mostraMultiSelect = false,
  mostrarGerarRelatorio = false,
  aoClicarEmGerarRelatorio, // Função de geração de relatório
  mostrarGerarExcel = false,
  aoClicarEmGerarExcel,
}) => {
  const theme = useTheme();

  return (
    <Box
      height={theme.spacing(5)}
      marginX={1}
      padding={2}
      paddingX={1}
      display="flex"
      gap={2}
      alignItems="center"
      component={Paper}
    >
      {mostraMultiSelect && (
        <MultipleSelectChip
          label={title || ""}
          options={listaMultiSelect || []}
          selectedOptions={selectedOptions || []}
          handleChange={
            handleChangeSelect as (event: SelectChangeEvent<number[]>) => void
          }
        />
      )}
      {mostraCheckBox && (
        <StatusCheckboxes
          options={listaCheckBox || []}
          checkBoxOptions={checkBoxOptions || []}
          handleChange={handleChangeCheck as (selected: number[]) => void}
        />
      )}
      <Box flex={1} display="flex" justifyContent="end">
        {mostrarGerarRelatorio && aoClicarEmGerarRelatorio && (
          <GenerateReportButton onClick={aoClicarEmGerarRelatorio} />
        )}
        {mostrarGerarExcel && aoClicarEmGerarExcel && (
          <Box ml={2}>
            {" "}
            {/* Adiciona uma margem de 2 espaços entre os botões */}
            <GenerateExcelButton onClick={aoClicarEmGerarExcel} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
