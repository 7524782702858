import React, { useState } from "react";
import {
  Divider,
  Drawer,
  List,
  useTheme,
  useMediaQuery,
  ListItemButton,
  ListItemIcon,
  Icon,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import { Box } from "@mui/system";
import { menuItems } from "../../../data";
import { ListaItemMenu } from "./ListaItemMenu";
import { useAuthContext, useDrawerContext } from "../../contexts";
import { useNavigate } from "react-router-dom"; // Importe useNavigate

interface MenuLateralProps {
  children?: React.ReactNode;
}

export const MenuLateral: React.FC<MenuLateralProps> = ({ children }) => {
  const [openItem, setOpenItem] = useState<string | null>(null);
  const theme = useTheme();
  const navigate = useNavigate(); // Obtenha a função de navegação
  const { logout } = useAuthContext();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { isDrawerOpen, toggleDrawerOpen } = useDrawerContext();

  const handleItemClick = (text: string, to: string, index: number) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === text ? null : text));

    if (menuItems[index].subItems?.length) {
    } else {
      navigate(to); // Navegue para a rota associada ao item
    }
  };

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        variant={smDown ? "temporary" : "temporary"}
        onClose={toggleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
          sx={{ bgcolor: "hsl(220.56deg 51.92% 40.78%)" }}
        >
          <Box
            width="100%"
            height={theme.spacing(10)}
            display="flex"
            alignItems="center"
            justifyContent="center" // Alinha o conteúdo no início
            position="sticky" // Fixa o Avatar quando o Drawer é rolado
            top={0} // Posiciona o Avatar no topo
            zIndex={theme.zIndex.appBar + 1}
            sx={{ bgcolor: "hsl(220.56deg 51.92% 40.78%)", color: "white" }}
          >
            <img
              src={require("../../img/logoces.webp")}
              alt="ERACON HUB"
              style={{ marginRight: 10, width: 150 }}
            />
            {/* <Typography variant="h5">HEALTHTECH</Typography> rgb(35 48 68) */}

            {/* <Avatar
                sx={{
                  height: theme.spacing(7),
                  width: theme.spacing(7),
                }}
                src="../../img/ERACON HUB 01.png"
              /> */}
          </Box>
          <Divider />
          <Typography
            variant="body1"
            sx={{
              fontSize: 14,
              color: "#EEEEEE",
              fontWeight: "10",
              fontFamily:
                'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            <List sx={{ bgcolor: "hsl(220.56deg 51.92% 40.78%)" }}>
              {menuItems.map((item, index) => (
                <ListaItemMenu
                  key={index}
                  item={item}
                  onClick={() => handleItemClick(item.text, item.to, index)}
                  open={openItem === item.text}
                  // onSubItemClick={(text) => console.log(`Subitem clicked: ${text}`)}
                />
              ))}
            </List>
            {/* <Divider   color='white' sx={{m:1}}/> */}
          </Typography>
        </Box>
        <Box
          bgcolor="rgb(13 76 153)"
          color="white"
          textAlign="center"
          p={2}
          position="fixed"
          bottom={0}
          width={theme.spacing(24)}
        >
          <ListItemButton
            onClick={logout}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
              <Icon sx={{ color: "white" }}>logout</Icon>
            </ListItemIcon>
            <ListItemText primary="Sair" sx={{ color: "white" }} />
          </ListItemButton>
        </Box>
      </Drawer>
      <Box
        height="100vh"
        marginLeft={smDown ? 0 : 0} //theme.spacing(28)}
        bgcolor="rgb(247 249 252)"
      >
        {children}
      </Box>
    </>
  );
};
