import { Button } from "@mui/material";

interface GenerateExcelButtonProps {
  onClick: () => void; // Função a ser chamada no clique do botão
}

export const GenerateExcelButton: React.FC<GenerateExcelButtonProps> = ({
  onClick,
}) => {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      Gerar XLS
    </Button>
  );
};
