// src/components/DiferencaFaturamento.tsx

import React, { useEffect, useState } from "react";
import { DashboardCard } from "../../../../shared/components/dashboads/DashCards";
import TrendingDownIcon from "@mui/icons-material/TrendingDown"; // Ícone para representar diferença ou queda

interface DiferencaFaturamentoProps {
  totalProvisao: number;
  totalFaturado: number;
}

export const DiferencaFaturamento: React.FC<DiferencaFaturamentoProps> = ({
  totalProvisao,
  totalFaturado,
}) => {
  const [diferenca, setDiferenca] = useState<number>(0);

  useEffect(() => {
    // Calcular a diferença
    const diff = totalProvisao - totalFaturado;
    setDiferenca(diff);
  }, [totalProvisao, totalFaturado]);

  return (
    <DashboardCard
      title="Diferença Faturamento"
      value={diferenca}
      footerText="A Faturar"
      gradientColors={["#ff5722", "#ff9800"]}
      Icon={TrendingDownIcon}
      isCurrency={true} // Para formatar como valor monetário
    />
  );
};
