import React from "react";
import { ListItem, ListItemIcon, Collapse, List, Typography, Box } from "@mui/material";
import Icon from "@mui/material/Icon";
import { MenuItem } from "../../../data";
import { useNavigate } from 'react-router-dom'; // Importe useNavigate
import PostAddIcon from '@mui/icons-material/PostAdd';

interface ListaItemMenuProps {
  item: MenuItem;
  onClick: () => void;
  open: boolean;
  onSubItemClick?: (text: string) => void; // tornando a propriedade opcional
  openSubItem?: string | null; // tornando a propriedade opcional
}


export const ListaItemMenu: React.FC<ListaItemMenuProps> = ({ item, onClick, open, onSubItemClick, openSubItem }) => {
  const { text, icon, subItems, to } = item;
  const navigate = useNavigate(); // Obtenha a função de navegação
  
  const handleSubItemClick = (text: string, to: string) => {
    if (onSubItemClick) {
      onSubItemClick(text);
    }
    navigate(to); // Navegue para a rota associada ao subitem
  };

  return (
    <>
    <Typography variant="body1" sx={{display:'flex',fontSize:14, color:'#EEEEEE', fontWeight: '100' ,fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'}}>
      <ListItem 
          button 
          onClick={() =>{
            if (!subItems) { // Se não houver subitens, navegue para a rota associada
              onClick(); // Execute a função de clique do item
              //navigate(to);
            } else { // Caso contrário, alterne a abertura/fechamento
              onClick();
            }
          }} 
          //sx={{ position: "relative", p:0,m:0.2, fontFamily:'Inter' }}
          >
        <ListItemIcon sx={{color:'rgb(238, 238, 238, 0.6)'}}>
          <Icon sx={{pl:0.9}}>{icon}</Icon>
        </ListItemIcon>
          {text}
    {subItems && (
      <Box marginLeft="auto">
        {open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
      </Box>
    )}
      </ListItem>
        </Typography>
      {subItems && (
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ zIndex: 1200 }}>
          <List component="div" disablePadding>
            {subItems.map((subItem, index) => (
              
              <ListItem
              key={index}
              button
              onClick={() => handleSubItemClick(subItem.text, subItem.to)}
              selected={openSubItem === subItem.text}
              sx={{mt:-0.8}}
              >
                <ListItemIcon>
                  <Icon>{subItem.icon}</Icon>
                </ListItemIcon>
                <Typography variant="body1" sx={{fontSize:14, color:'rgb(238, 238, 238, 0.6)', fontWeight: '100' ,fontFamily:'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'}}>
                {subItem.text}
                    {/* <ListItemText primary={} /> */}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};