import React, { useEffect, useState, useMemo } from "react";
import {
  ContasReceberService,
  IDetalheContasReceber,
} from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import { Skeleton } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import dayjs from "dayjs";

interface TotalizadoresDonutChartProps {
  empresasSelecionadas: string[];
}

export const TotalizadoresDonutChart: React.FC<
  TotalizadoresDonutChartProps
> = ({ empresasSelecionadas }) => {
  const [loading, setLoading] = useState(false);
  const [totais, setTotais] = useState({
    "0-30": 0,
    "31-60": 0,
    "61-90": 0,
    "91-120": 0,
    "121-150": 0,
    "151-180": 0,
    "181-210": 0,
    "211-240": 0,
    "+240": 0,
  });

  // Calcula o total somado de todos os intervalos
  const totalGeral = useMemo(
    () => Object.values(totais).reduce((acc, curr) => acc + curr, 0),
    [totais]
  );

  const fetchInadimplencias = async () => {
    setLoading(true);
    try {
      const response = await ContasReceberService.getAllWithoutPagination();

      if (response instanceof Error) {
        console.error(response.message);
        return;
      }

      const inadimplencias = response.filter((item: IDetalheContasReceber) => {
        const status = item.STATUS ? item.STATUS.trim().toUpperCase() : null;
        const situacao = item.SITUACAO
          ? item.SITUACAO.trim().toUpperCase()
          : null;

        return (
          status === "VENCIDO" &&
          situacao === "ABERTO" &&
          empresasSelecionadas.includes(item.EMPRESA)
        );
      });

      const groupedTotals = inadimplencias.reduce(
        (acc: any, curr: IDetalheContasReceber) => {
          const vencimentoDate = dayjs(curr.VENCIMENTO, "DD/MM/YYYY");
          const diasDeAtraso = dayjs().diff(vencimentoDate, "days");
          const bruto = parseFloat(String(curr.BRUTO).replace(",", ".") || "0");

          if (diasDeAtraso <= 30) {
            acc["0-30"] += bruto;
          } else if (diasDeAtraso > 30 && diasDeAtraso <= 60) {
            acc["31-60"] += bruto;
          } else if (diasDeAtraso > 60 && diasDeAtraso <= 90) {
            acc["61-90"] += bruto;
          } else if (diasDeAtraso > 90 && diasDeAtraso <= 120) {
            acc["91-120"] += bruto;
          } else if (diasDeAtraso > 120 && diasDeAtraso <= 150) {
            acc["121-150"] += bruto;
          } else if (diasDeAtraso > 150 && diasDeAtraso <= 180) {
            acc["151-180"] += bruto;
          } else if (diasDeAtraso > 180 && diasDeAtraso <= 210) {
            acc["181-210"] += bruto;
          } else if (diasDeAtraso > 210 && diasDeAtraso <= 240) {
            acc["211-240"] += bruto;
          } else if (diasDeAtraso > 240) {
            acc["+240"] += bruto;
          }

          return acc;
        },
        {
          "0-30": 0,
          "31-60": 0,
          "61-90": 0,
          "91-120": 0,
          "121-150": 0,
          "151-180": 0,
          "181-210": 0,
          "211-240": 0,
          "+240": 0,
        }
      );

      setTotais(groupedTotals);
    } catch (error) {
      console.error("Erro ao buscar as inadimplências:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInadimplencias();
  }, [empresasSelecionadas]);

  const option = useMemo(
    () => ({
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
      },
      series: [
        {
          name: "Totalizadores",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: true,
            position: "center", // Para colocar o total no centro
            fontSize: 20,
            fontWeight: "bold",
            formatter: () =>
              `Total\n${totalGeral.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}`,
          },
          labelLine: {
            show: false, // Esconde as linhas do rótulo central
          },
          data: [
            { value: totais["0-30"], name: "0 a 30 dias" },
            { value: totais["31-60"], name: "31 a 60 dias" },
            { value: totais["61-90"], name: "61 a 90 dias" },
            { value: totais["91-120"], name: "91 a 120 dias" },
            { value: totais["121-150"], name: "121 a 150 dias" },
            { value: totais["151-180"], name: "151 a 180 dias" },
            { value: totais["181-210"], name: "181 a 210 dias" },
            { value: totais["211-240"], name: "211 a 240 dias" },
            { value: totais["+240"], name: "+240 dias" },
          ],
        },
      ],
    }),
    [totais, totalGeral]
  );

  return (
    <div>
      {loading ? (
        <Skeleton sx={{ height: 400, width: "100%" }} />
      ) : (
        <ReactEcharts option={option} style={{ height: 400, width: "100%" }} />
      )}
    </div>
  );
};
