import axios from "axios";
import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IPostosProtheus {
    _contratosSet: any;
    codigoposto: string;
    codcoligada: number;
    codcliente: number;
    cliente: string;
    codigopostorm: string;
    codigocontratorm:string;
    codigoclienterm:string;
    posto: string;
    rua: string;
    numero: string;
    bairro: string;
    codmunicipio: string;
    cidade: string;
    pais: string;
    codetd: string;
    cep: string;
    codfilial: number;
    vagas: number;
    valortotal: number;
    empresa: string;
    idexternodocliente: string;
    idexternodoposto: string;
    postoinicio: string; // Use Date if this field represents a date
    postofim: string; // Use Date if this field represents a date
    encerrado: string; // Could be a flag ('0', '1')
    ccusto: string;
    integracao: string;
    idposto: string;
    idcontrato: string;
    totalContratos?: number; // Adicionando a propriedade
    totalPostos?: number;    // Adicionando a propriedade
}

type TListagemComTotalPostosProtheus = {
    data: IPostosProtheus[];
    totalCount: number;
    totalValor: number;
}

const getAll = async(page = 1, filter = '', cliente?: string, posto?: string, empresa?: string): Promise<TListagemComTotalPostosProtheus | Error> => {

    try {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('limit', Enviroment.LIMITE_DE_LINHAS.toString());
        if (cliente) params.append('cliente', cliente);
        if (posto) params.append('posto', posto);
        if (empresa) params.append('empresa', empresa);

        const { data, headers } = await Api.get(`/postos?${params.toString()}`);
        
        if (data) {
            return {
                data,
                totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
                totalValor: Number(headers['x-total-valor'] || 0)
            };
        }

        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};

// Serviço para busca sem paginação
const getAllWithoutPagination = async(cliente?: string, posto?: string, empresa?: string): Promise<IPostosProtheus[] | Error> => {
    try {
        const params = new URLSearchParams();
        if (cliente) params.append('cliente', cliente);
        if (posto) params.append('posto', posto);
        if (empresa) params.append('empresa', empresa);

        const { data } = await Api.get(`/postos?${params.toString()}&semPaginacao=true`);

        if (data) {
            return data;
        }

        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};


// Função para buscar as coordenadas
export const getCoordenadas = async (cidade: string, codetd: string) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?city=${cidade}&state=${codetd}&country=Brazil&format=json`
      );
  
      
  
      if (response.data && response.data.length > 0) {
        return {
          lat: parseFloat(response.data[0].lat),
          lng: parseFloat(response.data[0].lon),
        };
      }
    } catch (error) {
      console.error("Erro ao buscar coordenadas:", error);
    }
  
    return { lat: -15.7942, lng: -47.8822 }; // Coordenadas padrão (Brasil) caso a busca falhe
  };

export const PostosProtheusService = {
    getAll,
    getAllWithoutPagination,
    getCoordenadas
};
