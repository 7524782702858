import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { FerramentasDeDetalhe } from "../../shared/components";
import { LayoutBasePagina } from "../../shared/layouts";
import { useEffect, useState } from "react";
import { ContasPagarService } from "../../shared/services/api/financeiro/contasPagar/ContasPagarServices";
import { useDebounce } from "../../shared/hooks";

export const Dashboard = () => {
  const { debounce } = useDebounce();

  const [isLoadingContasPagar, setIsLoadingConvenios] = useState(true);
  const [totalCountContasPagar, setTotalCountConvenios] = useState(0);
  const [totalValorContasPagar, setTotalValorContasPagar] = useState(0);

  const [isLoadingLeitos, setIsLoadingLeitos] = useState(true);
  const [totalCountLeitos, setTotalCountLeitos] = useState(0);

  useEffect(() => {
    setIsLoadingConvenios(true);
    //setIsLoadingLeitos(true);
    ContasPagarService.getAll(1).then((result) => {
      setIsLoadingConvenios(false);
      console.log(result);

      if (result instanceof Error) {
        alert(result.message);
        return;
      }
      setTotalCountConvenios(result.totalCount);
      setTotalValorContasPagar(result.totalValor);
    });

    //   LeitosService.getAll(1)
    //   .then((result) => {
    //     setIsLoadingLeitos(false);

    //     if(result instanceof Error){
    //         alert(result.message)
    //         return;
    //     }
    //     setTotalCountLeitos(result.totalCount);
    //   })
  }, []);

  return (
    <LayoutBasePagina titulo="Pagina Inicial">
      <Divider />
      <Box width="100%" display="flex">
        <Grid container margin={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              {isLoadingContasPagar && (
                <Card sx={{ p: 0 }}>
                  <Skeleton height={200} />
                </Card>
              )}
              {!isLoadingContasPagar && (
                <Card>
                  <CardContent>
                    <Typography variant="h5" align="center">
                      Titulos
                    </Typography>
                    <Box
                      padding={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        {totalValorContasPagar.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            {isLoadingLeitos&&(
                                <Card sx={{p:0}}>
                                     <Skeleton height={200}/>
                                </Card>           
                                )}
                            {(!isLoadingLeitos) && (
                                <Card>
                                    <CardContent>
                                            <Typography variant="h5" align="center">
                                                Leitos
                                            </Typography>
                                        <Box padding={6} display="flex"  justifyContent="center" alignItems="center"> 
                                            <Typography variant="h1">
                                                {totalCountLeitos}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>                                   
                            )}
                        </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </LayoutBasePagina>
  );
};
