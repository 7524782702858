

export const Enviroment = {
    /**
     * Definicao do quantidade de registro na paginacao
     */
    LIMITE_DE_LINHAS: 7,
    /**
     * Texto padrao para os placeholder de busca
     */
    INPUT_DE_BUSCA: 'Pesquisar...',
    /**
     * Definicao do texto padrao para consultas com retorno vazio
     */
    LISTAGEM_VAZIO: 'Nenhum registro encontrado',
    /**
     * Definicao da URL Principal do backend
     */
    //URL_BASE: 'http://localhost:3898',
    URL_BASE: 'https://apiportal.grupoces.com.br',
    
    TOTAL:0
}