import React, { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { PostosProtheusService } from "../../../../shared/services/api/comercial/clientes/PostosServices";
import {
  FuncionariosRmService,
  IFuncionariosRm,
} from "../../../../shared/services/api/departamentoPessoal/funcionarios/FuncionariosServices";
import {
  FuncionariosNextiServices,
  IFuncionarioNexti,
} from "../../../../shared/services/api/departamentoPessoal/funcionarios/FuncionariosNextiServices";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface ClienteAgrupado {
  idexternodocliente: string;
  empresa: string;
  cliente: string;
  qtdPostos: number;
  qtdVagas: number;
  funcionariosRm: number;
  funcionariosNexti: number; // Adicionando a contagem de funcionários Nexti
  postos: IPosto[];
}

interface IPosto {
  idposto: string;
  idexternodoposto: string;
  ccusto: string;
  desccusto: string;
  nomePosto: string;
  vagas: number;
  cidade: string;
  funcionariosRm: number;
  funcionariosNexti: number; // Adicionando a contagem de funcionários Nexti
  codigopostorm: string;
  divergencia?: boolean; // Adicione esta linha
}

export const InformacoesComerciais: React.FC = () => {
  const [clientesData, setClientesData] = useState<ClienteAgrupado[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingFuncionarios, setLoadingFuncionarios] = useState(false); // Estado para controlar o carregamento dos funcionários
  const [tituloCarregando, setTituloCarregando] = useState(false);

  const [selectedCliente, setSelectedCliente] =
    useState<ClienteAgrupado | null>(null);
  const [selectedFuncionarios, setSelectedFuncionarios] = useState<
    IFuncionariosRm[]
  >([]);
  const [selectedFuncionariosNexti, setSelectedFuncionariosNexti] = useState<
    IFuncionarioNexti[]
  >([]);

  useEffect(() => {
    const carregarDados = async () => {
      setLoading(true);

      try {
        const postosResult =
          await PostosProtheusService.getAllWithoutPagination();
        if (postosResult instanceof Error) {
          toast.error(postosResult.message);
          setLoading(false);
          return;
        }

        const funcionariosRmResult =
          await FuncionariosRmService.getAllWithoutPagination();
        if (funcionariosRmResult instanceof Error) {
          toast.error(funcionariosRmResult.message);
          setLoading(false);
          return;
        }

        const funcionariosNextiResult =
          await FuncionariosNextiServices.getAllWithoutPagination();
        if (funcionariosNextiResult instanceof Error) {
          toast.error(funcionariosNextiResult.message);
          setLoading(false);
          return;
        }

        const clientesAgrupados = postosResult.reduce(
          (acc: ClienteAgrupado[], curr) => {
            const existingCliente = acc.find(
              (item) => item.idexternodocliente === curr.idexternodocliente
            );
            if (existingCliente) {
              existingCliente.qtdPostos += 1;
              existingCliente.qtdVagas += curr.vagas;

              const funcionariosAssociadosRm = funcionariosRmResult.filter(
                (func) =>
                  func.codigoclienterm === curr.codigoclienterm &&
                  func.codigocontratorm === curr.codigocontratorm &&
                  func.CODSECAO === curr.codigopostorm
              );

              const funcionariosAssociadosNexti =
                funcionariosNextiResult.filter(
                  (func) =>
                    func.externalWorkplaceId ===
                    curr.idexternodoposto.replace(".", ",")
                );

              existingCliente.funcionariosRm += funcionariosAssociadosRm.length;
              existingCliente.funcionariosNexti +=
                funcionariosAssociadosNexti.length;

              existingCliente.postos.push({
                idposto: curr.idposto,
                ccusto: curr.ccusto,
                desccusto: curr.cliente,
                idexternodoposto: curr.idexternodoposto,
                nomePosto: curr.posto,
                vagas: curr.vagas,
                cidade: curr.cidade + " - " + curr.codetd,
                funcionariosRm: funcionariosAssociadosRm.length,
                funcionariosNexti: funcionariosAssociadosNexti.length,
                codigopostorm: curr.codigopostorm,
              });
            } else {
              const funcionariosAssociadosRm = funcionariosRmResult.filter(
                (func) =>
                  func.codigoclienterm === curr.codigoclienterm &&
                  func.codigocontratorm === curr.codigocontratorm &&
                  func.CODSECAO === curr.codigopostorm
              );

              const funcionariosAssociadosNexti =
                funcionariosNextiResult.filter(
                  (func) =>
                    func.externalWorkplaceId ===
                    curr.idexternodoposto.replace(".", ",")
                );

              acc.push({
                idexternodocliente: curr.idexternodocliente,
                empresa: curr.empresa,
                cliente: curr.cliente,
                qtdPostos: 1,
                qtdVagas: curr.vagas,
                funcionariosRm: funcionariosAssociadosRm.length,
                funcionariosNexti: funcionariosAssociadosNexti.length,
                postos: [
                  {
                    idposto: curr.idposto,
                    ccusto: curr.ccusto,
                    desccusto: curr.cliente,
                    idexternodoposto: curr.idexternodoposto,
                    nomePosto: curr.posto,
                    vagas: curr.vagas,
                    cidade: curr.cidade + " - " + curr.codetd,
                    funcionariosRm: funcionariosAssociadosRm.length,
                    funcionariosNexti: funcionariosAssociadosNexti.length,
                    codigopostorm: curr.codigopostorm,
                  },
                ],
              });
            }

            return acc;
          },
          []
        );

        setClientesData(clientesAgrupados);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
        toast.error("Erro ao carregar os dados.");
        setLoading(false);
      }
    };

    carregarDados();
  }, []);

  const columns: GridColDef[] = [
    { field: "empresa", headerName: "EMPRESA", flex: 1 },
    { field: "cliente", headerName: "CLIENTE", flex: 1 },
    {
      field: "qtdPostos",
      headerName: "QTD COMERCIAL",
      type: "number",
      flex: 0.5,
    },
    {
      field: "qtdVagas",
      headerName: "QTD PROTHEUS",
      type: "number",
      flex: 0.5,
    },
    {
      field: "funcionariosRm",
      headerName: "QTD RM",
      type: "number",
      flex: 0.5,
    },
    {
      field: "funcionariosNexti",
      headerName: "QTD NEXTI",
      type: "number",
      flex: 0.5,
    }, // Nova coluna
    {
      field: "divergencia",
      headerName: "STATUS",
      headerAlign: "center", // Alinha o título ao centro
      align: "center", // Alinha os valores ao centro
      flex: 0.8,
      renderCell: (params) => {
        if (
          params.row.qtdVagas !== params.row.funcionariosRm ||
          params.row.qtdVagas !== params.row.funcionariosNexti ||
          params.row.funcionariosRm !== params.row.funcionariosNexti
        ) {
          return (
            <Chip
              icon={<ErrorOutlineIcon style={{ color: "red" }} />}
              label="Divergente"
              variant="outlined"
              style={{
                borderColor: "red",
                color: "red",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                fontWeight: "bold",
              }}
            />
          );
        }
        return (
          <Chip
            icon={<CheckCircleIcon style={{ color: "green" }} />}
            label="Válido"
            variant="outlined"
            style={{
              borderColor: "green",
              color: "green",
              backgroundColor: "rgba(0, 255, 0, 0.1)",
              fontWeight: "bold",
            }}
          />
        );
      },
    },
  ];

  const postosColumns: GridColDef[] = [
    { field: "idexternodoposto", headerName: "CODIGO", flex: 0.4 },
    { field: "ccusto", headerName: "CENTRO DE CUSTO", flex: 0.7 },
    { field: "cidade", headerName: "CIDADE", flex: 0.6 },
    { field: "nomePosto", headerName: "POSTO", flex: 1.5 },
    { field: "vagas", headerName: "QTD PROTHEUS", type: "number", flex: 0.5 },
    {
      field: "funcionariosRm",
      headerName: "QTD RM",
      type: "number",
      flex: 0.7,
    },
    {
      field: "funcionariosNexti",
      headerName: "QTD NEXTI",
      type: "number",
      flex: 0.7,
    }, // Nova coluna
    {
      field: "divergencia",
      headerName: "STATUS",
      headerAlign: "center", // Alinha o título ao centro
      align: "center", // Alinha os valores ao centro
      flex: 0.5,
      renderCell: (params) => {
        if (
          params.row.vagas !== params.row.funcionariosRm ||
          params.row.vagas !== params.row.funcionariosNexti ||
          params.row.funcionariosRm !== params.row.funcionariosNexti
        ) {
          return (
            <Chip
              icon={<ErrorOutlineIcon style={{ color: "red" }} />}
              label="Divergente"
              variant="outlined"
              style={{
                borderColor: "red",
                color: "red",
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                fontWeight: "bold",
              }}
            />
          );
        }
        return (
          <Chip
            icon={<CheckCircleIcon style={{ color: "green" }} />}
            label="Válido"
            variant="outlined"
            style={{
              borderColor: "green",
              color: "green",
              backgroundColor: "rgba(0, 255, 0, 0.1)",
              fontWeight: "bold",
            }}
          />
        );
      },
    },
  ];

  const funcionariosColumns: GridColDef[] = [
    { field: "matricula", headerName: "Matrícula", flex: 0.5 },
    { field: "nome", headerName: "Nome", flex: 1 },
    {
      field: "admissao",
      headerName: "Data de Admissão",
      flex: 1,
      renderCell: (params) => {
        const rawDate = params.value;
        if (rawDate) {
          const formattedDate = `${rawDate.slice(0, 2)}/${rawDate.slice(
            2,
            4
          )}/${rawDate.slice(4, 8)}`;
          return formattedDate;
        }
        return "";
      },
    },
    {
      field: "nascimento",
      headerName: "Data de Nascimento",
      flex: 1,
      renderCell: (params) => {
        const rawDate = params.value;
        if (rawDate) {
          const formattedDate = `${rawDate.slice(0, 2)}/${rawDate.slice(
            2,
            4
          )}/${rawDate.slice(4, 8)}`;
          return formattedDate;
        }
        return "";
      },
    },
    {
      field: "codsituacao",
      headerName: "Situacao",
      flex: 0.5,
      renderCell: (params) => {
        switch (params.row.codsituacao) {
          case "A":
            return "Ativo";
          case "F":
            return "Ferias";
          case "P":
            return "Afastado";
          case "T":
            return "Aviso";
          default:
            return "Outros Afastamentos"; // Caso o valor não seja nenhum dos listados
        }
      },
    },
  ];

  const funcionariosNextiColumns: GridColDef[] = [
    { field: "enrolment", headerName: "Matrícula", flex: 0.5 },
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "admissionDate",
      headerName: "Data de Admissão",
      flex: 1,
      renderCell: (params) => {
        const rawDate = params.value;
        if (rawDate) {
          const formattedDate = `${rawDate.slice(0, 2)}/${rawDate.slice(
            2,
            4
          )}/${rawDate.slice(4, 8)}`;
          return formattedDate;
        }
        return "";
      },
    },
    {
      field: "birthDate",
      headerName: "Data de Nascimento",
      flex: 1,
      renderCell: (params) => {
        const rawDate = params.value;
        if (rawDate) {
          const formattedDate = `${rawDate.slice(0, 2)}/${rawDate.slice(
            2,
            4
          )}/${rawDate.slice(4, 8)}`;
          return formattedDate;
        }
        return "";
      },
    },
    {
      field: "personSituationId",
      headerName: "Situação",
      flex: 0.5,
      renderCell: (params) =>
        params.row.personSituationId === 1 ? "Ativo" : "Ausente",
    },
  ];

  const handleRowDoubleClick = (params: any) => {
    const cliente = clientesData.find(
      (cliente) => cliente.idexternodocliente === params.row.idexternodocliente
    );
    setSelectedCliente(cliente || null);
  };

  const handleBack = () => {
    setSelectedCliente(null);
  };

  const handleBackToPostos = () => {
    setSelectedFuncionarios([]);
    setSelectedFuncionariosNexti([]);
  };

  const handlePostoDoubleClick = async (params: any) => {
    const postoSelecionado = params.row.codigopostorm;

    setLoadingFuncionarios(true);
    setTituloCarregando(true);

    try {
      const funcionariosRmResult =
        await FuncionariosRmService.getAllWithoutPagination();
      const funcionariosNextiResult =
        await FuncionariosNextiServices.getAllWithoutPagination();

      if (
        funcionariosRmResult instanceof Error ||
        funcionariosNextiResult instanceof Error
      ) {
        toast.error("Erro ao carregar os funcionários.");
        return;
      }

      const funcionariosDoPostoRm = funcionariosRmResult.filter(
        (func) => func.CODSECAO === postoSelecionado
      );
      const funcionariosDoPostoNexti = funcionariosNextiResult.filter(
        (func) =>
          func.externalWorkplaceId ===
          params.row.idexternodoposto.replace(".", ",")
      );

      setSelectedFuncionarios(funcionariosDoPostoRm);
      setSelectedFuncionariosNexti(funcionariosDoPostoNexti);
    } catch (error) {
      toast.error("Erro ao carregar os funcionários.");
    } finally {
      setLoadingFuncionarios(false);
      setTituloCarregando(false);
    }
  };

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <AppBar
        position="static"
        sx={{
          background: "linear-gradient(45deg,#FE6B8B 30%, #FF8E53 90%)",
          boxShadow: "none",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Toolbar>
          {selectedFuncionarios.length > 0 ||
          selectedFuncionariosNexti.length > 0 ? ( // Verifica se há funcionários RM ou Nexti selecionados
            <>
              <IconButton onClick={handleBackToPostos} sx={{ color: "#fff" }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                RELAÇÃO DE FUNCIONÁRIOS
              </Typography>
            </>
          ) : selectedCliente ? (
            <>
              <IconButton onClick={handleBack} sx={{ color: "#fff" }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {tituloCarregando
                  ? "BUSCANDO INFORMAÇÕES...."
                  : `POSTOS - ${selectedCliente.cliente}`}
              </Typography>
            </>
          ) : (
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              INFORMAÇÕES COMERCIAIS
            </Typography>
          )}
        </Toolbar>
      </AppBar>

      {loading ? (
        <LinearProgress />
      ) : selectedCliente ? (
        selectedFuncionarios.length > 0 ||
        selectedFuncionariosNexti.length > 0 ? (
          <>
            {/* DataGrid para Funcionários RM */}
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Funcionários RM
            </Typography>
            <DataGrid
              rows={selectedFuncionarios}
              columns={funcionariosColumns}
              getRowId={(row) => row.matricula}
              autoHeight
              pageSizeOptions={[5, 7, 10, 20, 50, 100]}
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 7, page: 0 },
                },
              }}
            />

            {/* DataGrid para Funcionários Nexti */}
            <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
              Funcionários Nexti
            </Typography>
            <DataGrid
              rows={selectedFuncionariosNexti}
              columns={funcionariosNextiColumns}
              getRowId={(row) => row.enrolment}
              autoHeight
              pageSizeOptions={[5, 7, 10, 20, 50, 100]}
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 7, page: 0 },
                },
              }}
            />
          </>
        ) : loadingFuncionarios ? (
          <LinearProgress />
        ) : (
          <DataGrid
            rows={selectedCliente.postos}
            columns={postosColumns}
            getRowId={(row) => row.idposto}
            pageSizeOptions={[5, 7, 10, 20, 50, 100]}
            autoHeight
            disableRowSelectionOnClick
            onRowDoubleClick={handlePostoDoubleClick}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 7, page: 0 },
              },
            }}
          />
        )
      ) : (
        <DataGrid
          rows={clientesData}
          columns={columns}
          getRowId={(row) => row.idexternodocliente}
          pagination
          pageSizeOptions={[5, 7, 10, 20, 50, 100]}
          autoHeight
          disableRowSelectionOnClick
          onRowDoubleClick={handleRowDoubleClick}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 7, page: 0 },
            },
          }}
        />
      )}
    </Box>
  );
};
