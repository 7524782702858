import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface AreaChartProps {
  data: { name: string; data: number[] }[]; // Série única de saldo
  categories: string[]; // Datas que serão mostradas no eixo X
}

export const AreaChart: React.FC<AreaChartProps> = ({ data, categories }) => {
  // Separa os dados em duas séries: uma para valores positivos e outra para valores negativos
  const positiveData = data[0].data.map((value) => (value >= 0 ? value : null));
  const negativeData = data[0].data.map((value) => (value < 0 ? value : null));

  const chartOptions: ApexOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        format: "dd/MM",
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          if (val !== null && val !== undefined) {
            return val.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }
          return ""; // Retorna uma string vazia se o valor for nulo ou indefinido
        },
      },
      title: {
        text: "Saldo (R$)",
      },
    },
    tooltip: {
      x: {
        format: "dd/MM",
      },
      y: {
        formatter: (val) => {
          if (val !== null && val !== undefined) {
            return val.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }
          return ""; // Retorna uma string vazia se o valor for nulo ou indefinido
        },
      },
    },
  };

  return (
    <Chart
      options={chartOptions}
      series={[
        {
          name: "Saldo Positivo",
          data: positiveData,
          color: "#2ecc71", // Verde para valores positivos
        },
        {
          name: "Saldo Negativo",
          data: negativeData,
          color: "#e74c3c", // Vermelho para valores negativos
        },
      ]}
      type="area"
      height={350}
    />
  );
};
