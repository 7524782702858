import { Search } from "@mui/icons-material";
import { Button, Icon, InputAdornment, Paper, TextField, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { Enviroment } from "../../environment";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import customParseFormat from 'dayjs/plugin/customParseFormat';  // Importa o plugin de formatação


dayjs.extend(customParseFormat);

interface IFerramentasDaListagem {
    textoDaBusca?: string;
    mostrarInputBusca?: boolean;
    aoMudarTextoDeBusca?: (novoTexto: string) => void;
    textoBotaoNovo?: string;
    mostrarBotaoNovo?: boolean;
    aoClicarEmNovo?: () => void;
    mostrarDatas?: boolean;
    dataInicio?: Date | null;
    dataFim?: Date | null;
    aoMudarDataInicio?: (novaData: Date | null) => void;
    aoMudarDataFim?: (novaData: Date | null) => void;
    mostrarBotaoFiltrar?: boolean;
    aoClicarEmFiltrar?: () => void;
    textoBotaoFiltrar?: string;
}

export const FerramentasDaListagem: React.FC<IFerramentasDaListagem> = ({
    textoDaBusca = '',
    mostrarInputBusca = false,
    aoMudarTextoDeBusca,
    textoBotaoNovo = 'Novo',
    mostrarBotaoNovo = true,
    aoClicarEmNovo,
    mostrarDatas = false,
    dataInicio = new Date(), // Definindo o valor padrão como a data atual
    dataFim = new Date(), // Definindo o valor padrão como a data atual
    aoMudarDataInicio,
    aoMudarDataFim,
    textoBotaoFiltrar = 'Filtrar',
    mostrarBotaoFiltrar = false,
    aoClicarEmFiltrar
}) => {
    const theme = useTheme();
    const [dataInicioLocal, setDataInicioLocal] = useState<Dayjs | null>(dayjs(dataInicio));
    const [dataFimLocal, setDataFimLocal] = useState<Dayjs | null>(dayjs(dataFim));

    const handleDataInicioChange = (newValue: Dayjs | null) => {
        setDataInicioLocal(newValue);
        aoMudarDataInicio?.(newValue?.toDate() || null);
    };

    const handleDataFimChange = (newValue: Dayjs | null) => {
        setDataFimLocal(newValue);
        aoMudarDataFim?.(newValue?.toDate() || null);
    };

    return (
        <Box
            height={theme.spacing(5)}
            marginX={1}
            padding={2}
            paddingX={1}
            display="flex"
            gap={2}
            alignItems="center"
            component={Paper}
        >
            {mostrarInputBusca && (
                <TextField        
                    size="small"
                    value={textoDaBusca}
                    onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}  
                    placeholder={Enviroment.INPUT_DE_BUSCA}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {mostrarDatas && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Data Início"
                            value={dataInicioLocal}
                            format="DD/MM/YYYY"
                            onChange={handleDataInicioChange}
                            slots={{
                                textField: textFieldProps => <TextField {...textFieldProps} size="small" />
                            }}
                        />
                        <DatePicker
                            label="Data Fim"
                            value={dataFimLocal}
                            format="DD/MM/YYYY"
                            onChange={handleDataFimChange}
                            slots={{
                                textField: textFieldProps => <TextField {...textFieldProps} size="small" />
                            }}
                        />
                </LocalizationProvider>
            )}
            <Box flex={1} gap={2} display="flex" justifyContent="end">
                {mostrarBotaoNovo && (
                    <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        onClick={aoClicarEmNovo}
                        endIcon={<Icon>add</Icon>}
                    >
                        {textoBotaoNovo}
                    </Button>
                )}
                {mostrarBotaoFiltrar && (
                    <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        onClick={aoClicarEmFiltrar}
                        endIcon={<Icon>search</Icon>}
                    >
                        {textoBotaoFiltrar}
                    </Button>
                )}
            </Box>
        </Box>
    );  
}
