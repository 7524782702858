// src/shared/components/dashboads/DashCards.tsx

import React from "react";
import { Card, CardContent, Typography, Box, Avatar } from "@mui/material";

interface DashboardCardProps {
  title: string;
  value: string | number;
  footerText: string;
  gradientColors: string[];
  Icon: React.ElementType;
  isCurrency?: boolean; // Adicionada a propriedade para definir se o valor será formatado como moeda
  onClick?: () => void; // Função onClick opcional
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  value,
  footerText,
  gradientColors,
  Icon,
  isCurrency = true, // Define como true por padrão
}) => {
  return (
    <Card
      sx={{
        background: `linear-gradient(45deg, ${gradientColors[0]} 30%, ${gradientColors[1]} 90%)`,
        color: "#FFFFFF",
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ textAlign: "start" }}>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Icon sx={{ fontSize: 30 }} />
            <Typography sx={{ marginLeft: 1 }}>{footerText}</Typography>
          </Box>
          <Typography variant="h5">
            {isCurrency
              ? value.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const DashboardCardQtd: React.FC<DashboardCardProps> = ({
  title,
  value,
  footerText,
  gradientColors,
  Icon,
}) => {
  return (
    <Card
      sx={{
        background: `linear-gradient(45deg, ${gradientColors[0]} 30%, ${gradientColors[1]} 90%)`,
        color: "#FFFFFF",
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ textAlign: "start" }}>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Icon sx={{ fontSize: 30 }} />
            <Typography sx={{ marginLeft: 1 }}>{footerText}</Typography>
          </Box>
          <Typography variant="h4">{value}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const DashboardCardInadimplencia: React.FC<DashboardCardProps> = ({
  title,
  value,
  footerText,
  gradientColors,
  Icon,
}) => {
  return (
    <Card
      sx={{
        background: `linear-gradient(45deg, ${gradientColors[0]} 30%, ${gradientColors[1]} 90%)`,
        color: "#FFFFFF",
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ textAlign: "start" }}>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Icon sx={{ fontSize: 30 }} />
            <Typography sx={{ marginLeft: 1 }}>{footerText}</Typography>
          </Box>
          <Typography variant="h4">
            {value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const DashboardCardSemBackground: React.FC<DashboardCardProps> = ({
  title,
  value,
  Icon,
  footerText,
  gradientColors,
  onClick, // Função onClick opcional
}) => {
  return (
    <Card
      onClick={onClick} // Aplica a função onClick, se ela existir
      sx={{
        backgroundColor: "#f5f9ff", // Fundo leve do card
        color: "#333",
        minHeight: 100,
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
        cursor: onClick ? "pointer" : "default", // Cursor de pointer apenas se houver onClick
      }}
    >
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontWeight: "bold", color: "#2e3c5d" }}
        >
          {Number(value).toLocaleString("pt-BR", { maximumFractionDigits: 0 })}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {footerText}
        </Typography>
      </Box>
      <Avatar
        sx={{
          backgroundImage: `linear-gradient(45deg, ${gradientColors[0]} 30%, ${gradientColors[1]} 90%)`, // Gradiente no Avatar
          width: 50,
          height: 50,
        }}
      >
        <Icon sx={{ fontSize: 28, color: "#fff" }} />
      </Avatar>
    </Card>
  );
};
