import { AxiosError } from "axios";



export const errorInterceptor = (error: AxiosError)=>{

    if(error.message == "Network Error"){
        return Promise.reject(new Error ("Erro de conexão"));
    }

    if(error.response?.status === 401){
        // qualquer tratativa
        window.location.href = "/login"; // Altere a URL de acordo com sua rota de login
    }

    return Promise.reject(error);
    
}