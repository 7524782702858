import { Api } from "../axios-config";

interface IAuth {
    accessToken: string;
}


const auth = async(usuario: string, senha: string):  Promise<IAuth | Error> => {
    try {

        const { data } = await Api.post(`/entrar`, {usuario, senha});

        if(data){
            return data;
        }

        return new Error('Erro ao logar.');

    } catch (error) {
        return new Error((error as {message: string}).message || 'Erro ao logar.');
    }
}

export const AuthService = {
    auth,
}