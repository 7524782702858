import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  ContasReceberService,
  IDetalheContasReceber,
} from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import {
  AppBar,
  Box,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { TotalizadoresDonutChart } from "./TotalizadoresDonutChart";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";

interface InadimplenciaDataGridProps {
  empresasSelecionadas: string[];
}

interface InadimplenciaRow {
  id: string;
  cliente: string;
  "0-30": string;
  "31-60": string;
  "61-90": string;
  "91-120": string;
  "121-150": string;
  "151-180": string;
  "181-210": string;
  "211-240": string;
  "+240": string;
  total: string;
}

export const InadimplenciaDataGrid: React.FC<InadimplenciaDataGridProps> = ({
  empresasSelecionadas,
}) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<InadimplenciaRow[]>([]);
  const [detalhes, setDetalhes] = useState<IDetalheContasReceber[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<string | null>(null);

  // Função para formatar para Real
  const formatToCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  // Função para calcular os totais das colunas
  const calculateTotals = (detalhes: IDetalheContasReceber[]) => {
    const totalBruto = detalhes.reduce(
      (acc, curr) => acc + (curr.BRUTO || 0),
      0
    );
    const totalINSS = detalhes.reduce(
      (acc, curr) => acc + (curr.F2_VALINSS || 0),
      0
    );
    const totalISS = detalhes.reduce(
      (acc, curr) => acc + (curr.F2_VALISS || 0),
      0
    );
    const totalIRRF = detalhes.reduce(
      (acc, curr) => acc + (curr.F2_VALIRRF || 0),
      0
    );
    const totalCSLL = detalhes.reduce(
      (acc, curr) => acc + (curr.F2_VALCSLL || 0),
      0
    );
    const totalPIS = detalhes.reduce(
      (acc, curr) => acc + (curr.F2_VALPIS || 0),
      0
    );
    const totalCOFINS = detalhes.reduce(
      (acc, curr) => acc + (curr.F2_VALCOFI || 0),
      0
    );
    const totalLiquido = detalhes.reduce(
      (acc, curr) => acc + (curr.LIQUIDO || 0),
      0
    );

    // Formatar para string usando a função formatToCurrency
    return {
      totalBruto: formatToCurrency(totalBruto),
      totalINSS: formatToCurrency(totalINSS),
      totalISS: formatToCurrency(totalISS),
      totalIRRF: formatToCurrency(totalIRRF),
      totalCSLL: formatToCurrency(totalCSLL),
      totalPIS: formatToCurrency(totalPIS),
      totalCOFINS: formatToCurrency(totalCOFINS),
      totalLiquido: formatToCurrency(totalLiquido),
    };
  };
  const [totals, setTotals] = useState({
    totalBruto: "R$ 0,00",
    totalINSS: "R$ 0,00",
    totalISS: "R$ 0,00",
    totalIRRF: "R$ 0,00",
    totalCSLL: "R$ 0,00",
    totalPIS: "R$ 0,00",
    totalCOFINS: "R$ 0,00",
    totalLiquido: "R$ 0,00",
  });

  useEffect(() => {
    const fetchInadimplencias = async () => {
      setLoading(true);
      try {
        const response = await ContasReceberService.getAllWithoutPagination();

        if (response instanceof Error) {
          console.error(response.message);
          return;
        }

        const inadimplencias = response.filter(
          (item: IDetalheContasReceber) => {
            const status = item.STATUS
              ? item.STATUS.trim().toUpperCase()
              : null;
            const situacao = item.SITUACAO
              ? item.SITUACAO.trim().toUpperCase()
              : null;

            return (
              status === "VENCIDO" &&
              situacao === "ABERTO" &&
              empresasSelecionadas.includes(item.EMPRESA)
            );
          }
        );

        const groupedByClient = inadimplencias.reduce(
          (acc: any, curr: IDetalheContasReceber) => {
            const cliente = curr.A1_NOME.trim();
            const vencimentoDate = dayjs(curr.VENCIMENTO, "DD/MM/YYYY");
            const diasDeAtraso = dayjs().diff(vencimentoDate, "days");

            if (!acc[cliente]) {
              acc[cliente] = {
                id: cliente,
                cliente,
                "0-30": formatToCurrency(0),
                "31-60": formatToCurrency(0),
                "61-90": formatToCurrency(0),
                "91-120": formatToCurrency(0),
                "121-150": formatToCurrency(0),
                "151-180": formatToCurrency(0),
                "181-210": formatToCurrency(0),
                "211-240": formatToCurrency(0),
                "+240": formatToCurrency(0),
                total: formatToCurrency(0),
              };
            }

            const bruto = parseFloat(
              String(curr.BRUTO).replace(",", ".") || "0"
            );

            const updateCurrencyValue = (
              currentValue: string,
              addValue: number
            ) => {
              const numericValue =
                parseFloat(currentValue.replace(/[^\d,-]/g, "")) || 0;
              return formatToCurrency(numericValue + addValue);
            };

            // Distribuindo os valores conforme os dias de atraso e somando ao total
            if (diasDeAtraso <= 30) {
              acc[cliente]["0-30"] = updateCurrencyValue(
                acc[cliente]["0-30"],
                bruto
              );
            } else if (diasDeAtraso > 30 && diasDeAtraso <= 60) {
              acc[cliente]["31-60"] = updateCurrencyValue(
                acc[cliente]["31-60"],
                bruto
              );
            } else if (diasDeAtraso > 60 && diasDeAtraso <= 90) {
              acc[cliente]["61-90"] = updateCurrencyValue(
                acc[cliente]["61-90"],
                bruto
              );
            } else if (diasDeAtraso > 90 && diasDeAtraso <= 120) {
              acc[cliente]["91-120"] = updateCurrencyValue(
                acc[cliente]["91-120"],
                bruto
              );
            } else if (diasDeAtraso > 120 && diasDeAtraso <= 150) {
              acc[cliente]["121-150"] = updateCurrencyValue(
                acc[cliente]["121-150"],
                bruto
              );
            } else if (diasDeAtraso > 150 && diasDeAtraso <= 180) {
              acc[cliente]["151-180"] = updateCurrencyValue(
                acc[cliente]["151-180"],
                bruto
              );
            } else if (diasDeAtraso > 180 && diasDeAtraso <= 210) {
              acc[cliente]["181-210"] = updateCurrencyValue(
                acc[cliente]["181-210"],
                bruto
              );
            } else if (diasDeAtraso > 210 && diasDeAtraso <= 240) {
              acc[cliente]["211-240"] = updateCurrencyValue(
                acc[cliente]["211-240"],
                bruto
              );
            } else if (diasDeAtraso > 240) {
              acc[cliente]["+240"] = updateCurrencyValue(
                acc[cliente]["+240"],
                bruto
              );
            }

            // Atualizando o total
            acc[cliente].total = updateCurrencyValue(acc[cliente].total, bruto);

            return acc;
          },
          {}
        );

        const rowsData = Object.values(groupedByClient) as InadimplenciaRow[];
        setRows(rowsData);
        setDetalhes(inadimplencias); // Guardar todos os detalhes para filtragem futura

        // Se um cliente estiver selecionado, calcular os totais
        if (selectedCliente) {
          const detalhesFiltrados = inadimplencias.filter(
            (detalhe) => detalhe.A1_NOME === selectedCliente
          );
          setTotals(calculateTotals(detalhesFiltrados));
        }
      } catch (error) {
        console.error("Erro ao buscar as inadimplências:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInadimplencias();
  }, [empresasSelecionadas, selectedCliente]);

  // Função para capturar o duplo clique e abrir os detalhes
  const handleRowDoubleClick = (params: GridRowParams) => {
    setSelectedCliente(params.row.cliente);
  };

  // Função para voltar à visão inicial
  const handleBack = () => {
    setSelectedCliente(null);
    setTotals({
      totalBruto: "R$ 0,00",
      totalINSS: "R$ 0,00",
      totalISS: "R$ 0,00",
      totalIRRF: "R$ 0,00",
      totalCSLL: "R$ 0,00",
      totalPIS: "R$ 0,00",
      totalCOFINS: "R$ 0,00",
      totalLiquido: "R$ 0,00",
    });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inadimplência");
    XLSX.writeFile(workbook, "inadimplencia.xlsx");
  };

  const columns: GridColDef[] = [
    { field: "cliente", headerName: "Cliente", flex: 2 },
    { field: "0-30", headerName: "0 a 30", flex: 1, align: "right" },
    { field: "31-60", headerName: "31 a 60", flex: 1, align: "right" },
    { field: "61-90", headerName: "61 a 90", flex: 1, align: "right" },
    { field: "91-120", headerName: "91 a 120", flex: 1, align: "right" },
    { field: "121-150", headerName: "121 a 150", flex: 1, align: "right" },
    { field: "151-180", headerName: "151 a 180", flex: 1, align: "right" },
    { field: "181-210", headerName: "181 a 210", flex: 1, align: "right" },
    { field: "211-240", headerName: "211 a 240", flex: 1, align: "right" },
    { field: "+240", headerName: "+240", flex: 1, align: "right" },
    {
      field: "total",
      headerName: "Total",
      flex: 1.5,
      align: "right",
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: "#FF6584",
            fontWeight: "bold",
            color: "white",
            padding: "4px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {params.value}
        </Box>
      ),
    },
  ];

  const exportDetalhesToExcel = () => {
    const detalhesFiltrados = detalhes.filter(
      (detalhe) => detalhe.A1_NOME === selectedCliente
    );

    // Prepara os dados do Excel
    const worksheet = XLSX.utils.json_to_sheet(
      detalhesFiltrados.map((detalhe) => ({
        Cliente: detalhe.A1_NOME,
        "Centro de Custo": detalhe.C5_XCC,
        Emissão: detalhe.EMISSAO,
        "N. Fiscal": detalhe.E1_NFELETR,
        "Valor Bruto": detalhe.BRUTO,
        INSS: detalhe.F2_VALINSS,
        ISS: detalhe.F2_VALISS,
        IRRF: detalhe.F2_VALIRRF,
        CSLL: detalhe.F2_VALCSLL,
        PIS: detalhe.F2_VALPIS,
        COFINS: detalhe.F2_VALCOFI,
        Líquido: detalhe.LIQUIDO,
        Vencimento: detalhe.VENCIMENTO,
        Tipo: detalhe.E1_TIPO,
        Status: detalhe.STATUS,
        "N. Dias": detalhe.DIAS,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Detalhes Cliente");
    XLSX.writeFile(workbook, `Detalhes_${selectedCliente}.xlsx`);
  };

  const detalhesColumns: GridColDef[] = [
    { field: "EMISSAO", headerName: "EMISSAO", flex: 1 },
    { field: "E1_NFELETR", headerName: "N. FISCAL", flex: 1 },
    {
      field: "BRUTO",
      headerName: "VALOR BRUTO",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALINSS",
      headerName: "INSS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALISS",
      headerName: "ISS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALIRRF",
      headerName: "IRRF",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALCSLL",
      headerName: "CSLL",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALPIS",
      headerName: "PIS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "F2_VALCOFI",
      headerName: "COFINS",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "LIQUIDO",
      headerName: "LIQUIDO",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    { field: "VENCIMENTO", headerName: "VENCIMENTO", flex: 1 },
    { field: "E1_TIPO", headerName: "TIPO", flex: 1 },
    { field: "STATUS", headerName: "STATUS", flex: 1 },
    { field: "DIAS", headerName: "N. DIAS", flex: 1 },
  ];

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          background: "linear-gradient(45deg,#FE6B8B 30%, #FF8E53 90%)",
          boxShadow: "none",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Toolbar>
          {selectedCliente ? (
            <>
              <IconButton onClick={handleBack} sx={{ color: "#fff" }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {`CLIENTE - ${selectedCliente}`}
              </Typography>
              <IconButton
                onClick={exportDetalhesToExcel}
                sx={{ color: "#fff" }}
              >
                <DownloadIcon />
              </IconButton>
            </>
          ) : (
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              <IconButton onClick={exportToExcel} sx={{ color: "#fff" }}>
                <AlignVerticalTopIcon />
              </IconButton>
              INADIMPLÊNCIA
            </Typography>
          )}
          {/* Ícone de download no final da barra */}
          {!selectedCliente && (
            <IconButton onClick={exportToExcel} sx={{ color: "#fff" }}>
              <DownloadIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {loading ? (
        <Skeleton sx={{ height: 500, width: "100%" }} />
      ) : selectedCliente ? (
        <div>
          {/* DataGrid com os detalhes */}
          <DataGrid
            rows={detalhes.filter(
              (detalhe) => detalhe.A1_NOME === selectedCliente
            )}
            columns={detalhesColumns}
            getRowId={(row) => `${row.ID}`}
            autoHeight
          />

          {/* DataGrid com o resumo (totalizadores) */}
          <Box sx={{ marginTop: 2, marginBottom: 10 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Resumo Totalizadores
            </Typography>
            <DataGrid
              rows={[
                {
                  id: "totalizadores",
                  BRUTO: totals.totalBruto,
                  INSS: totals.totalINSS,
                  ISS: totals.totalISS,
                  IRRF: totals.totalIRRF,
                  CSLL: totals.totalCSLL,
                  PIS: totals.totalPIS,
                  COFINS: totals.totalCOFINS,
                  LIQUIDO: totals.totalLiquido,
                },
              ]}
              columns={[
                { field: "BRUTO", headerName: "Total Bruto", flex: 1 },
                { field: "INSS", headerName: "Total INSS", flex: 1 },
                { field: "ISS", headerName: "Total ISS", flex: 1 },
                { field: "IRRF", headerName: "Total IRRF", flex: 1 },
                { field: "CSLL", headerName: "Total CSLL", flex: 1 },
                { field: "PIS", headerName: "Total PIS", flex: 1 },
                { field: "COFINS", headerName: "Total COFINS", flex: 1 },
                { field: "LIQUIDO", headerName: "Total Líquido", flex: 1 },
              ]}
              hideFooter
              autoHeight
            />
          </Box>
        </div>
      ) : (
        <>
          <DataGrid
            rows={rows}
            columns={columns}
            onRowDoubleClick={handleRowDoubleClick}
            autoHeight
          />
          {!selectedCliente && (
            <TotalizadoresDonutChart
              empresasSelecionadas={empresasSelecionadas}
            />
          )}
        </>
      )}
    </div>
  );
};
