// GenerateReportButton.tsx
import { Button } from "@mui/material";

interface GenerateReportButtonProps {
  onClick: () => void; // Recebe a função de clique
}

export const GenerateReportButton: React.FC<GenerateReportButtonProps> = ({
  onClick,
}) => {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      Gerar PDF
    </Button>
  );
};
