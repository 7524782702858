// src/shared/components/dashboads/DashCards.tsx
import React from "react";
import { Card, CardContent, Typography, Box, Avatar } from "@mui/material";

interface DashboardCardProps {
  title: string;
  value: string | number;
  footerText: string;
  Icon: React.ElementType;
  gradientColors: string[]; // Aceitar array de strings para gradiente
  onClick?: () => void; // Função onClick opcional
}

export const DashboardCardOperacional: React.FC<DashboardCardProps> = ({
  title,
  value,
  Icon,
  footerText,
  gradientColors,
  onClick, // Função onClick opcional
}) => {
  return (
    <Card
      onClick={onClick} // Aplica a função onClick, se ela existir
      sx={{
        backgroundColor: "#f5f9ff", // Fundo leve do card
        color: "#333",
        minHeight: 100,
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
        cursor: onClick ? "pointer" : "default", // Cursor de pointer apenas se houver onClick
      }}
    >
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontWeight: "bold", color: "#2e3c5d" }}
        >
          {Number(value).toLocaleString("pt-BR", { maximumFractionDigits: 0 })}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {footerText}
        </Typography>
      </Box>
      <Avatar
        sx={{
          backgroundImage: `linear-gradient(45deg, ${gradientColors[0]} 30%, ${gradientColors[1]} 90%)`, // Gradiente no Avatar
          width: 50,
          height: 50,
        }}
      >
        <Icon sx={{ fontSize: 28, color: "#fff" }} />
      </Avatar>
    </Card>
  );
};
