import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { PostosProtheusService } from "../../../../shared/services/api/comercial/clientes/PostosServices";
import { ContasReceberService } from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import dayjs from "dayjs";

interface ProvisaoAnualProps {
  empresasSelecionadas: string[];
}

export const ProvisaoAnual: React.FC<ProvisaoAnualProps> = ({
  empresasSelecionadas,
}) => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const fetchProvisaoAnual = async () => {
      setLoading(true);
      try {
        const responsePostos =
          await PostosProtheusService.getAllWithoutPagination();
        const responseFaturado =
          await ContasReceberService.getAllWithoutPagination();

        if (
          responsePostos instanceof Error ||
          responseFaturado instanceof Error
        ) {
          console.error("Erro ao buscar os dados");
          return;
        }

        // Inicializar dados mensais para o gráfico (12 meses do ano)
        const monthlyData = Array.from({ length: 12 }, (_, index) => ({
          month: index + 1,
          provisao: 0,
          faturado: 0, // Inicializa o faturado para cada mês
          diferenca: 0, // Inicializa a diferença para cada mês
        }));

        // Processar dados de provisão dos postos com filtro de empresas
        responsePostos.forEach((posto: any) => {
          if (empresasSelecionadas.includes(posto.empresa)) {
            const valorRecorrente = posto.valortotal || 0;
            const inicioDate = dayjs(posto.postoinicio, "YYYYMMDD");
            const fimDate = posto.postofim
              ? dayjs(posto.postofim, "YYYYMMDD")
              : dayjs().endOf("year");

            // Loop por todos os meses de 2024
            const currentYear = 2024; // Fixar o ano de 2024
            for (let month = 1; month <= 12; month++) {
              const startOfMonth = dayjs(`${currentYear}-${month}-01`);
              const endOfMonth = startOfMonth.endOf("month");

              // Verificar se o posto está ativo durante o mês
              if (
                (inicioDate.isBefore(endOfMonth) ||
                  inicioDate.isSame(endOfMonth)) &&
                (fimDate.isAfter(startOfMonth) || fimDate.isSame(startOfMonth))
              ) {
                // Adicionar o valor integral
                monthlyData[month - 1].provisao += valorRecorrente;
              }
            }
          }
        });

        // Processar dados de faturamento
        responseFaturado.forEach((conta: any) => {
          const tipo = conta.E1_TIPO
            ? conta.E1_TIPO.trim().toUpperCase()
            : null;
          if (
            tipo === "FATURADO" &&
            empresasSelecionadas.includes(conta.EMPRESA)
          ) {
            const faturamentoDate = dayjs(conta.emi2, "YYYYMMDD");
            if (faturamentoDate.year() === 2024) {
              const month = faturamentoDate.month(); // Mês do faturamento (0-based)
              monthlyData[month].faturado += conta.BRUTO || 0;
            }
          }
        });

        // Calcular a diferença entre o faturado e o previsto
        monthlyData.forEach((data) => {
          data.diferenca = data.faturado - data.provisao;
        });

        // Montar os dados para o gráfico
        setChartData({
          months: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          provisao: monthlyData.map((data) => data.provisao),
          faturado: monthlyData.map((data) => data.faturado),
          diferenca: monthlyData.map((data) => data.diferenca),
        });
      } catch (error) {
        console.error(
          "Erro ao buscar os dados de provisão e faturamento anual:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProvisaoAnual();
  }, [empresasSelecionadas]);

  const getChartOptions = () => ({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: ["Provisão Comercial", "Faturado", "A Faturar"],
    },
    xAxis: [
      {
        type: "category",
        data: chartData?.months,
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "Valores (R$)",
        axisLabel: {
          formatter: "R$ {value}",
        },
      },
    ],
    series: [
      {
        name: "Provisão Comercial",
        type: "bar",
        itemStyle: { color: "#782cf2" }, // Cor para a provisão comercial
        data: chartData?.provisao.map((value: number) => value.toFixed(2)),
      },
      {
        name: "Faturado",
        type: "bar",
        itemStyle: { color: "#3cba54" }, // Cor para o faturado
        data: chartData?.faturado.map((value: number) => value.toFixed(2)),
      },
      {
        name: "A Faturar",
        type: "bar",
        itemStyle: { color: "#ff5722" }, // Cor para o faturado
        // itemStyle: {
        //   color: (params: any) => (params.value >= 0 ? "#00b0ff" : "#ff4c4c"), // Azul para positivo, vermelho para negativo
        // },
        data: chartData?.diferenca.map((value: number) => value.toFixed(2)),
      },
    ],
  });

  return (
    <div>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <ReactECharts
          option={getChartOptions()}
          style={{ height: 500, width: "100%" }}
        />
      )}
    </div>
  );
};
