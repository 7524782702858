import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IFuncionariosRm {
    empresa: string; // C&S Matriz ou C&S Filial
    codcoligada: number; // 12 ou 17
    codsecao: string;
    codigocontratorm: string; // Substring de CODSECAO (1-15)
    codigoclienterm: string; // Substring de CODSECAO (1-10)
    coligadarm: number;
    CODSECAO:string;
    endereco: string;
    adminDevice: boolean; // Sempre 'false' na sua consulta
    admissao: string; // Data de admissão no formato ddMMyyyy000000
    nascimento: string; // Data de nascimento no formato ddMMyyyy000000
    cidade: string;
    country: string; // Sempre 'BRASIL'
    cpf: string;
    bairro: string;
    email: string;
    matricula: string; // CHAPA
    idexternoempresa: number; // 12 ou 17
    externalId: string; // '12-CHAPA' ou '17-CHAPA'
    idexternoposto: string; // Campo calculado na consulta
    pai: string | null; // Nome do pai
    federatedUnitInitials: string; // Estado de nascimento
    sexo: string;
    numero: string;
    estcivil: string; // Estado civil
    mae: string | null; // Nome da mãe
    nome: string;
    codsituacao: string;
    phone: string;
    publicArea: string; // Descrição da área pública
    cep: string;
    intNexti: string; // COMP.INT_NEXTI
    intProtheus: string; // COMP.INT_PROTHEUS
    intDataNexti: string; // COMP.INT_DATANEXTI
    sicNexti: string; // COMP.SIC_NEXTI
    sicProtheus: string; // COMP.SIC_PROTHEUS
    intDemitidoNexti: string; // COMP.INT_DEMITIDONEXTI
    intDemitidoProtheus: string; // COMP.INT_DEMITIDOPROTHEUS
  }
  

type TListagemComTotalFuncionariosProtheus = {
    data: IFuncionariosRm[];
    totalCount: number;
    totalValor: number;
}

const getAll = async(page = 1, filter = ''): Promise<TListagemComTotalFuncionariosProtheus | Error> => {

    try {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('limit', Enviroment.LIMITE_DE_LINHAS.toString());


        const { data, headers } = await Api.get(`/postos?${params.toString()}`);
        
        if (data) {
            return {
                data,
                totalCount: Number(headers['x-total-count'] || Enviroment.LIMITE_DE_LINHAS),
                totalValor: Number(headers['x-total-valor'] || 0)
            };
        }

        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};

// Serviço para busca sem paginação
const getAllWithoutPagination = async(): Promise<IFuncionariosRm[] | Error> => {
    try {
        const params = new URLSearchParams();

        const { data } = await Api.get(`/funcionarios?${params.toString()}&semPaginacao=true`);

        if (data) {
            return data;
        }

        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};


export const FuncionariosRmService = {
    getAll,
    getAllWithoutPagination
};
