import React, { useEffect, useState } from "react";
import { DashboardCard } from "../../../../shared/components/dashboads/DashCards";
import ReceiptIcon from "@mui/icons-material/Receipt"; // Ícone que representa faturamento
import {
  ContasReceberService,
  IDetalheContasReceber,
} from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import dayjs from "dayjs";

interface ValorFaturadoNoMesProps {
  empresasSelecionadas: string[];
  setTotalFaturado: (value: number) => void;
  mesAtual: number; // Adiciona uma prop para o mês atual
}

export const ValorFaturadoNoMes: React.FC<ValorFaturadoNoMesProps> = ({
  empresasSelecionadas,
  setTotalFaturado,
  mesAtual, // Adiciona o mês como prop
}) => {
  const [loading, setLoading] = useState(false);
  const [totalFaturado, setLocalTotalFaturado] = useState<number>(0);

  useEffect(() => {
    const fetchTotalFaturado = async () => {
      setLoading(true);
      try {
        const response = await ContasReceberService.getAllWithoutPagination();

        if (response instanceof Error) {
          console.error(response.message);
          return;
        }

        const currentYear = dayjs().year();

        // Filtrar registros pelas empresas selecionadas e apenas os registros faturados no mês atual
        const faturados = response.filter((item: IDetalheContasReceber) => {
          const tipo = item.E1_TIPO ? item.E1_TIPO.trim().toUpperCase() : null;

          if (
            tipo === "FATURADO" &&
            empresasSelecionadas.includes(item.EMPRESA)
          ) {
            const faturamentoDate = dayjs(item.emi2, "YYYYMMDD");
            return (
              faturamentoDate.month() + 1 === mesAtual && // Compara dinamicamente com o mês atual
              faturamentoDate.year() === currentYear
            );
          }
          return false;
        });

        // Somar o valor bruto de todos os títulos faturados do mês atual
        const total = faturados.reduce(
          (acc, curr) => acc + (curr.BRUTO || 0),
          0
        );

        setLocalTotalFaturado(total);
        setTotalFaturado(total); // Atualiza o estado totalFaturado no Dashboard
      } catch (error) {
        console.error("Erro ao buscar o valor faturado:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalFaturado();
  }, [empresasSelecionadas, mesAtual]); // Adiciona mesAtual como dependência

  return (
    <DashboardCard
      title={`Valor Faturado (${dayjs()
        .month(mesAtual - 1)
        .format("MMMM")})`} // Exibe dinamicamente o nome do mês
      value={loading ? "Carregando..." : totalFaturado}
      footerText="Faturado no Mês"
      gradientColors={["#17b2dd", "#1b8dfa"]}
      Icon={ReceiptIcon}
      isCurrency={true}
    />
  );
};
