import React, { useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { IListagemFluxoCaixaDetalhado } from "../../../../shared/services/api/financeiro/fluxoCaixa/FluxoCaixaDetalhadoServices";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download"; // Ícone de download

interface FluxoCaixaDetalhadoDataGridProps {
  data?: IListagemFluxoCaixaDetalhado[];
  loading: boolean;
  error?: string | null;
}

export const FluxoCaixaDetalhadoDataGrid: React.FC<
  FluxoCaixaDetalhadoDataGridProps
> = ({ data = [], loading, error }) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("pt-BR");
  };

  const formatToCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  const columns: GridColDef[] = [
    { field: "E5_DATA", headerName: "Data", flex: 1 },
    { field: "A6_NOME", headerName: "Banco", flex: 1 },
    { field: "NATUREZA", headerName: "Natureza", flex: 1 },
    {
      field: "E5_VLMULTA",
      headerName: "Valor Multa",
      flex: 1,
      type: "number",
      renderCell: (params) => (
        <Box sx={{ textAlign: "right", width: "100%" }}>
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    { field: "E5_HISTOR", headerName: "Histórico", flex: 2 },
    { field: "DESCRICAO", headerName: "Descrição", flex: 2 },
    { field: "LOCALIZADOR", headerName: "Localizador", flex: 1 },
    {
      field: "Entrada",
      headerName: "Entrada",
      flex: 1,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            textAlign: "right",
            width: "100%",
            color: params.value > 0 ? "green" : "inherit",
          }}
        >
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "Saida",
      headerName: "Saída",
      flex: 1,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            textAlign: "right",
            width: "100%",
            color: params.value > 0 ? "red" : "inherit",
          }}
        >
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
    {
      field: "SaldoDia",
      headerName: "Saldo do Dia",
      flex: 1,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            textAlign: "right",
            width: "100%",
            color: params.value < 0 ? "red" : "green",
          }}
        >
          {formatToCurrency(params.value)}
        </Box>
      ),
    },
  ];

  const filteredData = data.filter(
    (row) => row.Entrada !== 0 || row.Saida !== 0
  );
  const rowsWithIds = filteredData.map((row, index) => ({
    ...row,
    id: `row-${index}`,
  }));

  // Função para exportar os dados para Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowsWithIds);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FluxoCaixaDetalhado");
    XLSX.writeFile(workbook, "FluxoCaixaDetalhado.xlsx");
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: Number(event.target.value),
    }));
  };

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}
    >
      <Tooltip title="Exportar para Excel">
        <IconButton color="primary" onClick={exportToExcel}>
          <DownloadIcon />
          <Typography>Exportar</Typography>
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  );

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : rowsWithIds.length === 0 ? (
        <Typography color="textSecondary" align="center" sx={{ mt: 2 }}>
          Nenhum dado encontrado.
        </Typography>
      ) : (
        <DataGrid
          rows={rowsWithIds}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => setPaginationModel(model)}
          pagination
          disableColumnMenu
          autoHeight
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            noRowsOverlay: {
              children: (
                <Typography color="textSecondary" align="center" sx={{ mt: 2 }}>
                  Nenhum dado encontrado.
                </Typography>
              ),
            },
          }}
        />
      )}
    </Box>
  );
};
