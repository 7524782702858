// src/pages/DashboardFinanceiro/DashboardFinanceiro.tsx

import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Skeleton, Divider } from "@mui/material";
import { LayoutBasePagina } from "../../../shared/layouts";
import { ContasPagarService } from "../../../shared/services/api/financeiro/contasPagar/ContasPagarServices";
import { useDebounce } from "../../../shared/hooks";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TaskIcon from '@mui/icons-material/Task';
import PageviewIcon from '@mui/icons-material/Pageview';
import GetAppIcon from '@mui/icons-material/GetApp';
import { DashboardCard } from "../../../shared/components";


export const DashboardFinanceiro = () => {
    const { debounce } = useDebounce();
    const [isLoadingContasPagar, setIsLoadingConvenios] = useState(true);
    const [totalCountContasPagar, setTotalCountConvenios] = useState(0);

    useEffect(() => {
        setIsLoadingConvenios(true);

        ContasPagarService.getAll(1)
            .then((result) => {
                setIsLoadingConvenios(false);

                if (result instanceof Error) {
                    alert(result.message);
                    return;
                }
                setTotalCountConvenios(result.totalCount);
            });
    }, []);

    return (
        <LayoutBasePagina titulo="Dashboard Financeiro">
            <Divider />
            <Box width="100%" display="flex">
                <Grid container spacing={2} margin={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        {isLoadingContasPagar ? (
                            <Card sx={{ p: 2 }}>
                                <Skeleton height={150} />
                            </Card>
                        ) : (
                            <DashboardCard
                                title="Títulos"
                                value={totalCountContasPagar}
                                footerText="Total de títulos"
                                gradientColors={['#FE6B8B', '#FF8E53']}
                                Icon={AttachMoneyIcon}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard
                            title="Tasks"
                            value="145"
                            footerText="Task performance"
                            gradientColors={['#e53935', '#d32f2f']}
                            Icon={TaskIcon}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard
                            title="Page Views"
                            value="290+"
                            footerText="Daily views"
                            gradientColors={['#43a047', '#388e3c']}
                            Icon={PageviewIcon}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard
                            title="Downloads"
                            value="500"
                            footerText="App store downloads"
                            gradientColors={['#1e88e5', '#1976d2']}
                            Icon={GetAppIcon}
                        />
                    </Grid>
                </Grid>
            </Box>
        </LayoutBasePagina>
    );
};
