// src/components/ValorTotalInadimplencia.tsx

import React, { useEffect, useState } from "react";
import { DashboardCard } from "../../../../shared/components/dashboads/DashCards";
import WarningIcon from "@mui/icons-material/Warning";
import {
  ContasReceberService,
  IDetalheContasReceber,
} from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import dayjs from "dayjs";

interface ValorTotalInadimplenciaProps {
  empresasSelecionadas: string[];
}

export const ValorTotalInadimplencia: React.FC<
  ValorTotalInadimplenciaProps
> = ({ empresasSelecionadas }) => {
  const [loading, setLoading] = useState(false);
  const [totalInadimplencia, setTotalInadimplencia] = useState<number>(0);

  useEffect(() => {
    const fetchTotalInadimplencia = async () => {
      setLoading(true);
      try {
        const response = await ContasReceberService.getAllWithoutPagination();

        if (response instanceof Error) {
          console.error(response.message);
          return;
        }

        // Obter o mês e ano atual
        const currentMonth = dayjs().month() + 1;
        const currentYear = dayjs().year();

        // Filtrar registros pelas empresas selecionadas e apenas os registros de inadimplência do mês atual
        const inadimplencias = response.filter(
          (item: IDetalheContasReceber) => {
            const status = item.STATUS
              ? item.STATUS.trim().toUpperCase()
              : null;
            const situacao = item.SITUACAO
              ? item.SITUACAO.trim().toUpperCase()
              : null;

            if (
              status === "VENCIDO" &&
              situacao === "ABERTO" &&
              empresasSelecionadas.includes(item.EMPRESA)
            ) {
              const vencimentoDate = dayjs(item.VENCIMENTO, "DD/MM/YYYY");
              return (
                vencimentoDate.month() + 1 === currentMonth &&
                vencimentoDate.year() === currentYear
              );
            }
            return false;
          }
        );

        // Somar o valor líquido de todas as contas inadimplentes do mês
        const total = inadimplencias.reduce(
          (acc, curr) => acc + (curr.BRUTO || 0),
          0
        );

        setTotalInadimplencia(total);
      } catch (error) {
        console.error("Erro ao buscar o valor de inadimplência:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalInadimplencia();
  }, [empresasSelecionadas]);

  return (
    <DashboardCard
      title="Inadimplência em Aberto (Mês Atual)"
      value={loading ? "Carregando..." : totalInadimplencia}
      footerText="Inadimplência"
      gradientColors={["#f8494c", "#e52e2e"]}
      Icon={WarningIcon}
      isCurrency={true}
    />
  );
};
