export const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  
  export const empresas = [
    {id:1002, label:'C&S FILIAL'},
    {id:1001, label:'C&S MATRIZ'},
    {id:9001, label:'SCE SERVICOS'},
  ];

  export const statusOptions = [
    { id: 1, label: 'Liberado' },
    { id: 2, label: 'Aguardando Aprovação' },
  ];