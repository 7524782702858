import React, { useState } from "react";
import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { LayoutBasePagina } from "../../../shared/layouts";
import { FluxoDeCaixaCards } from "./components/Cards";
import { FiltroFluxoDeCaixa } from "./components/FiltroFluxoDeCaixa";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import {
  FluxoCaixaService,
  IListagemFluxoCaixaResumido,
} from "../../../shared/services/api/financeiro/fluxoCaixa/FluxoCaixaResumidoServices";
import { AreaChart } from "./components/AreaChart";
import { FluxoCaixaDetalhadoDataGrid } from "./components/FluxoCaixaDetalhadoDataGrid";
import {
  FluxoCaixaDetalhadoService,
  IListagemFluxoCaixaDetalhado,
} from "../../../shared/services/api/financeiro/fluxoCaixa/FluxoCaixaDetalhadoServices";

// Função para formatar datas no formato yyyy-mm-dd
const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const FluxoDeCaixa: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [contasPagarDiario, setContasPagarDiario] = useState<
    { x: string; y: number }[]
  >([]);
  const [contasReceberDiario, setContasReceberDiario] = useState<
    { x: string; y: number }[]
  >([]);
  const [provComercialData, setProvComercialData] = useState<
    { x: string; y: number }[]
  >([]);
  const [provReceberData, setProvReceberData] = useState<
    { x: string; y: number }[]
  >([]);
  const [provPagarData, setProvPagarData] = useState<
    { x: string; y: number }[]
  >([]);
  const [provComprasData, setProvComprasData] = useState<
    { x: string; y: number }[]
  >([]);
  const [saldoDiario, setSaldoDiario] = useState<{ x: string; y: number }[]>(
    []
  );
  const [detailedData, setDetailedData] = useState<
    IListagemFluxoCaixaDetalhado[]
  >([]); // Estado para dados detalhados

  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [selectedMonthRange, setSelectedMonthRange] = useState<number[]>([
    1, 12,
  ]);

  // Estados adicionais para banco e empresa
  const [banco, setBanco] = useState<string[]>(["BANCO DO BRASIL-122404"]); // Alterado para string[]
  const [empresa, setEmpresa] = useState("C&S MATRIZ");
  const [incluirProvReceber, setIncluirProvReceber] = useState(true);
  const [incluirProvComercial, setIncluirProvComercial] = useState(true);
  const [incluirProvPagar, setIncluirProvPagar] = useState(true);
  const [incluirProvCompras, setIncluirProvCompras] = useState(true);
  const [totalProvisaoComercial, setTotalProvisaoComercial] = useState(0);
  const [totalProvisaoReceber, setTotalProvisaoReceber] = useState(0);
  const [totalProvisaoPagar, setTotalProvisaoPagar] = useState(0);
  const [totalProvisaoCompras, setTotalProvisaoCompras] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const dataInicio = new Date(selectedYear, selectedMonthRange[0] - 1, 1);
      const ultimoDiaMes = new Date(selectedYear, selectedMonthRange[1], 0);
      const formattedDataInicio = formatDateToYYYYMMDD(dataInicio);
      const formattedDataFim = formatDateToYYYYMMDD(ultimoDiaMes);

      // Converte o array de bancos para uma string separada por vírgula
      const bancoQuery = banco.join(",");

      const fluxoCaixaResumoResponse =
        await FluxoCaixaService.getAllWithoutPagination(
          bancoQuery, // Transforma o array banco em uma string separada por vírgulas
          empresa,
          formattedDataInicio,
          formattedDataFim,
          incluirProvReceber,
          incluirProvComercial,
          incluirProvPagar,
          incluirProvCompras
        );

      if (fluxoCaixaResumoResponse instanceof Error) {
        throw new Error(fluxoCaixaResumoResponse.message);
      }

      if (
        !fluxoCaixaResumoResponse ||
        !Array.isArray(fluxoCaixaResumoResponse)
      ) {
        throw new Error("A resposta da API não contém dados.");
      }
      // Consome os dados detalhados
      const fluxoCaixaDetalhadoResponse =
        await FluxoCaixaDetalhadoService.getAllWithoutPagination(
          bancoQuery,
          empresa,
          formattedDataInicio,
          formattedDataFim,
          incluirProvReceber,
          incluirProvComercial,
          incluirProvPagar,
          incluirProvCompras
        );

      if (fluxoCaixaDetalhadoResponse instanceof Error) {
        throw new Error(fluxoCaixaDetalhadoResponse.message);
      }

      if (
        fluxoCaixaDetalhadoResponse &&
        Array.isArray(fluxoCaixaDetalhadoResponse)
      ) {
        setDetailedData(fluxoCaixaDetalhadoResponse);
      } else {
        console.error("A estrutura esperada não contém um array.");
      }

      // Mapeia os dados para cada linha do gráfico
      setContasPagarDiario(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.MovSaida,
        }))
      );
      setContasReceberDiario(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.MovEntrada,
        }))
      );
      setProvComercialData(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.ProvComercial,
        }))
      );
      setProvReceberData(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.ProvReceber,
        }))
      );
      setProvPagarData(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.ProvPagar,
        }))
      );
      setProvComprasData(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.ProvCompras,
        }))
      );
      setSaldoDiario(
        fluxoCaixaResumoResponse.map((item) => ({
          x: item.E5_DATA,
          y: item.SaldoDia,
        }))
      );

      // Calcula os totais das provisões
      setTotalProvisaoComercial(
        fluxoCaixaResumoResponse.reduce(
          (acc, curr) => acc + (curr.ProvComercial || 0),
          0
        )
      );
      setTotalProvisaoReceber(
        fluxoCaixaResumoResponse.reduce(
          (acc, curr) => acc + (curr.ProvReceber || 0),
          0
        )
      );
      setTotalProvisaoPagar(
        fluxoCaixaResumoResponse.reduce(
          (acc, curr) => acc + (curr.ProvPagar || 0),
          0
        )
      );
      setTotalProvisaoCompras(
        fluxoCaixaResumoResponse.reduce(
          (acc, curr) => acc + (curr.ProvCompras || 0),
          0
        )
      );
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const unifiedCategories = Array.from(
    new Set([
      ...contasPagarDiario.map((item) => item.x),
      ...contasReceberDiario.map((item) => item.x),
      ...saldoDiario.map((item) => item.x),
    ])
  ).sort();

  const getSyncedData = (
    data: { x: string; y: number }[],
    categories: string[]
  ) => {
    const dataMap = data.reduce((acc, item) => {
      acc[item.x] = item.y;
      return acc;
    }, {} as Record<string, number>);

    return categories.map((category) => dataMap[category] || 0);
  };

  return (
    <LayoutBasePagina titulo="FLUXO DE CAIXA">
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <FiltroFluxoDeCaixa
            banco={banco}
            onBancoChange={setBanco}
            selectedYear={selectedYear}
            onYearChange={setSelectedYear}
            selectedMonths={selectedMonthRange}
            onMonthChange={setSelectedMonthRange}
            onApplyFilter={fetchData}
            incluirProvReceber={incluirProvReceber}
            incluirProvComercial={incluirProvComercial}
            incluirProvPagar={incluirProvPagar}
            incluirProvCompras={incluirProvCompras}
            onToggleProvReceber={() => setIncluirProvReceber((prev) => !prev)}
            onToggleProvComercial={() =>
              setIncluirProvComercial((prev) => !prev)
            }
            onToggleProvPagar={() => setIncluirProvPagar((prev) => !prev)}
            onToggleProvCompras={() => setIncluirProvCompras((prev) => !prev)}
            empresa={empresa}
            onEmpresaChange={setEmpresa}
          />

          {loading ? (
            <>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" width="100%" height={100} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" width="100%" height={100} />
              </Grid>
            </>
          ) : (
            <FluxoDeCaixaCards
              cardsData={[
                {
                  title: "Saldo Inicial",
                  value: saldoDiario.length > 0 ? saldoDiario[0].y : 0,
                  footerText: "SALDO INICIAL",
                  Icon:
                    saldoDiario.length > 0 && saldoDiario[0].y >= 0
                      ? TrendingUpIcon
                      : TrendingDownIcon,
                  gradientColors:
                    saldoDiario.length > 0 && saldoDiario[0].y >= 0
                      ? ["#2ecc71", "#27ae60"]
                      : ["#e74c3c", "#c0392b"],
                },
                {
                  title: "Provisão Comercial",
                  value: totalProvisaoComercial,
                  footerText: "PROVISÃO COMERCIAL",
                  Icon: SwitchAccountIcon,
                  gradientColors: ["#00c6ff", "#0072ff"],
                },
                {
                  title: "Provisão Receber",
                  value: totalProvisaoReceber,
                  footerText: "PROVISÃO A RECEBER",
                  Icon: AttachMoneyIcon,
                  gradientColors: ["#2ecc71", "#27ae60"],
                },
                {
                  title: "Provisão Pagar",
                  value: totalProvisaoPagar,
                  footerText: "PROVISÃO A PAGAR",
                  Icon: AttachMoneyIcon,
                  gradientColors: ["#e74c3c", "#c0392b"],
                },
                {
                  title: "Provisão Compras",
                  value: totalProvisaoCompras,
                  footerText: "PROVISÃO DE COMPRAS",
                  Icon: MonetizationOnIcon,
                  gradientColors: ["#f39c12", "#f1c40f"],
                },
                {
                  title: "Saldo Final",
                  value:
                    saldoDiario.length > 0
                      ? saldoDiario[saldoDiario.length - 1].y
                      : 0,
                  footerText: "SALDO FINAL",
                  Icon:
                    saldoDiario.length > 0 &&
                    saldoDiario[saldoDiario.length - 1].y >= 0
                      ? TrendingUpIcon
                      : TrendingDownIcon,
                  gradientColors:
                    saldoDiario.length > 0 &&
                    saldoDiario[saldoDiario.length - 1].y >= 0
                      ? ["#2ecc71", "#27ae60"]
                      : ["#e74c3c", "#c0392b"],
                },
              ]}
            />
          )}

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              SALDO CONSOLIDADO
            </Typography>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={350} />
            ) : (
              <Paper sx={{ padding: 3 }}>
                <AreaChart
                  data={[
                    {
                      name: "Saldo Diário",
                      data: getSyncedData(saldoDiario, unifiedCategories),
                    },
                  ]}
                  categories={unifiedCategories.map((dateStr) =>
                    dateStr.slice(6, 8)
                  )}
                />
              </Paper>
            )}
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ padding: 3 }}>
              <Typography variant="h6" gutterBottom>
                DETALHAMENTO
              </Typography>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={350} />
              ) : (
                <FluxoCaixaDetalhadoDataGrid
                  data={detailedData}
                  loading={loading}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </LayoutBasePagina>
  );
};
