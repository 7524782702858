import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box, Paper,useTheme } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IOption {
  id: number;
  label: string;
}

interface IMultipleSelectChipProps {
  label: string;
  options: IOption[];
  selectedOptions: number[];
  handleChange: (event: SelectChangeEvent<number[]>) => void;
}

export const MultipleSelectChip: React.FC<IMultipleSelectChipProps> = ({
  label,
  options,
  selectedOptions,
  handleChange,
}) => {
  const theme = useTheme();
  return (
     <FormControl sx={{ m: 1, width: 250 }}>
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.map(id => options.find(opt => opt.id === id)?.label).join(', ')}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
    
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedOptions.indexOf(option.id) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
     </FormControl>
  );
};
