import axios from "axios";
import { errorInterceptor, responseInterceptor } from "./interceptors";
import { Enviroment } from "../../../environment";

// Função para obter o token dinamicamente do sessionStorage
const getAccessToken = () => {
  const accessToken = sessionStorage.getItem('APP_ACCESS_TOKEN');
  return accessToken ? `Bearer ${JSON.parse(accessToken)}` : '';
};

// Criação da instância Axios
const Api = axios.create({
  baseURL: Enviroment.URL_BASE,
});

// Interceptor para adicionar o token em todas as requisições
Api.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, (error) => Promise.reject(error));

// Interceptores de resposta e erro
Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error)
);

export { Api };
