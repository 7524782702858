// src/components/ValorLiquidadoNoMes.tsx

import React, { useEffect, useState } from "react";
import { DashboardCard } from "../../../../shared/components/dashboads/DashCards";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Ícone que representa status recebido
import {
  ContasReceberService,
  IDetalheContasReceber,
} from "../../../../shared/services/api/financeiro/contasReceber/ContasReceberServices";
import dayjs from "dayjs";

interface ValorLiquidadoNoMesProps {
  empresasSelecionadas: string[];
}

export const ValorLiquidadoNoMes: React.FC<ValorLiquidadoNoMesProps> = ({
  empresasSelecionadas,
}) => {
  const [loading, setLoading] = useState(false);
  const [totalLiquidado, setTotalLiquidado] = useState<number>(0);

  useEffect(() => {
    const fetchTotalLiquidado = async () => {
      setLoading(true);
      try {
        const response = await ContasReceberService.getAllWithoutPagination();

        if (response instanceof Error) {
          console.error(response.message);
          return;
        }

        // Obter o mês e ano atual
        const currentMonth = dayjs().month() + 1;
        const currentYear = dayjs().year();

        // Filtrar registros pelas empresas selecionadas e apenas os registros de títulos liquidados no mês atual
        const liquidados = response.filter((item: IDetalheContasReceber) => {
          const status = item.STATUS ? item.STATUS.trim().toUpperCase() : null;

          if (
            status === "RECEBIDO" &&
            empresasSelecionadas.includes(item.EMPRESA)
          ) {
            const recebimentoDate = dayjs(item.EMISSAO, "DD/MM/YYYY");
            return (
              recebimentoDate.month() + 1 === currentMonth &&
              recebimentoDate.year() === currentYear
            );
          }
          return false;
        });

        // Somar o valor líquido de todos os títulos liquidados do mês
        const total = liquidados.reduce(
          (acc, curr) => acc + (curr.LIQUIDO || 0),
          0
        );

        setTotalLiquidado(total);
      } catch (error) {
        console.error("Erro ao buscar o valor liquidado:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalLiquidado();
  }, [empresasSelecionadas]);

  return (
    <DashboardCard
      title="Valor Liquidado (Mês Atual)"
      value={loading ? "Carregando..." : totalLiquidado}
      footerText="Liquidado"
      gradientColors={["#29ac7d", "#25efa5"]}
      Icon={CheckCircleIcon}
      isCurrency={true}
    />
  );
};
