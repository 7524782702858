/* eslint-disable no-restricted-globals */

import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ContasPagarService,
  IListagemContasPagar,
} from "../../../shared/services/api/financeiro/contasPagar/ContasPagarServices";
import {
  DashboardCard,
  DashboardCardInadimplencia,
  DashboardCardQtd,
  FerramentasDaListagem,
  FerramentasDeFiltros,
} from "../../../shared/components";
import { LayoutBasePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Enviroment } from "../../../shared/environment";
import SituacaoCell from "../../../shared/components/Forms/SituacaoCell";
import dayjs from "dayjs";
import {
  empresas,
  statusOptions,
} from "../../../shared/components/ferramentas-de-filtros/options";
import { SelectChangeEvent } from "@mui/material/Select";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { toast } from "react-toastify";
import { ContasPagarPDF } from "./components/ContasPagarPDF";
import { ExportExcel } from "./components/ExportExcel";

export const ListagemDeContasPagar: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListagemContasPagar[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [dataInicio, setDataInicio] = useState<Date | null>(new Date());
  const [dataFim, setDataFim] = useState<Date | null>(new Date());
  const [selectedEmpresas, setSelectedEmpresas] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [totalValorContasPagar, setTotalValorContasPagar] = useState(0);
  const [gerarRelatorio, setGerarRelatorio] = useState(false); // Estado para controlar a renderização do componente PDF
  const [totalInadimplencia, setTotalInadimplencia] = useState(0); // Estado para total de inadimplência

  const [excelData, setExcelData] = useState<any[]>([]); // Estado para armazenar os dados do Excel
  const [generateExcel, setGenerateExcel] = useState(false); // Controle de geração do Excel

  const busca = useMemo(() => {
    return searchParams.get("busca") || "";
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get("pagina") || "1");
  }, [searchParams]);

  const carregarDados = (resetPage = false) => {
    setIsLoading(true);

    const dataInicioFormatada = dataInicio
      ? dayjs(dataInicio).format("YYYY-MM-DD")
      : "";
    const dataFimFormatada = dataFim ? dayjs(dataFim).format("YYYY-MM-DD") : "";

    debounce(() => {
      ContasPagarService.getAll(
        pagina,
        busca,
        dataInicioFormatada,
        dataFimFormatada,
        selectedEmpresas,
        selectedStatus
      ).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          // Mostra a mensagem "Nenhum registro encontrado" para erro 500
          toast.error(Enviroment.LISTAGEM_VAZIO);

          // Zera os valores e a quantidade em caso de erro
          setRows([]);
          setTotalCount(0);
          setTotalValorContasPagar(0);
          setTotalInadimplencia(0); // Zera o valor de inadimplência em caso de erro
          return;
        }

        // Caso não haja registros, mostrar a mensagem de "Nenhum registro encontrado"
        if (result.data.length === 0) {
          toast.info(Enviroment.LISTAGEM_VAZIO); // Mensagem definida no seu arquivo de configuração
          setRows([]);
          setTotalCount(0);
          setTotalValorContasPagar(0);
          setTotalInadimplencia(0); // Zera o valor de inadimplência
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
          setTotalValorContasPagar(result.totalValor);
          calcularInadimplencia(result.data); // Calcula a inadimplência
        }

        if (resetPage) {
          setSearchParams({ busca, pagina: "1" }, { replace: true });
        }
      });
    });
  };

  const calcularInadimplencia = (rows: IListagemContasPagar[]) => {
    const inadimplencia = rows
      .filter((row) => dayjs(row.E2_VENCTO).isBefore(dayjs(), "day")) // Filtra as contas vencidas
      .reduce((acc, row) => acc + row.E2_VALOR, 0); // Soma os valores vencidos

    setTotalInadimplencia(inadimplencia); // Atualiza o estado de inadimplência
  };

  useEffect(() => {
    carregarDados();
  }, [busca, pagina, selectedEmpresas, selectedStatus]);

  const handleEmpresasChange = (
    event: number[] | SelectChangeEvent<number[]>
  ) => {
    const value = Array.isArray(event) ? event : event.target.value;
    setSelectedEmpresas(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
    setSearchParams({ busca, pagina: "1" }, { replace: true });
  };

  const handleStatusChange = (selected: number[]) => {
    setSelectedStatus(selected);
    setSearchParams({ busca, pagina: "1" }, { replace: true });
  };

  const dataParaChart = [
    {
      value: rows.filter((row) => row.E2_SITUACAO === "Liberado").length,
      label: "Liberado",
    },
    {
      value: rows.filter((row) => row.E2_SITUACAO === "Aguardando Aprovação")
        .length,
      label: "Aguardando Aprovação",
    },
  ];

  const handleGenerateReport = () => {
    const dataInicioFormatada = dataInicio
      ? dayjs(dataInicio).format("YYYY-MM-DD")
      : "";
    const dataFimFormatada = dataFim ? dayjs(dataFim).format("YYYY-MM-DD") : "";

    // Chamar o serviço para buscar todos os dados sem paginação
    ContasPagarService.getAllWithoutPagination(
      busca,
      dataInicioFormatada,
      dataFimFormatada,
      selectedEmpresas,
      selectedStatus
    )
      .then((result) => {
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        }

        // Gera o PDF com todos os dados retornados
        ContasPagarPDF({
          rows: result, // Recebe todos os registros
          dataInicio: dataInicioFormatada,
          dataFim: dataFimFormatada,
          selectedEmpresas,
          selectedStatus,
          busca,
        });
      })
      .catch((error) => {
        console.error("Erro ao gerar relatório:", error);
        console.log(error);
        toast.error("Erro ao gerar o relatório.");
      });
  };

  const handleGenerateExcel = () => {
    const dataInicioFormatada = dataInicio
      ? dayjs(dataInicio).format("YYYY-MM-DD")
      : "";
    const dataFimFormatada = dataFim ? dayjs(dataFim).format("YYYY-MM-DD") : "";

    // Chamar o serviço para buscar todos os dados sem paginação
    ContasPagarService.getAllWithoutPagination(
      busca,
      dataInicioFormatada,
      dataFimFormatada,
      selectedEmpresas,
      selectedStatus
    )
      .then((result) => {
        if (result instanceof Error) {
          toast.error(result.message);
          return;
        }

        // Define os dados no estado e ativa a geração do Excel
        setExcelData(result); // Define os dados que serão exportados
        setGenerateExcel(true); // Ativa o componente ExportExcel para gerar o arquivo
      })
      .catch((error) => {
        console.error("Erro ao gerar relatório:", error);
        toast.error("Erro ao gerar o relatório.");
      });
  };

  // Resetar o estado para impedir múltiplas gerações de Excel
  const onExcelExportComplete = () => {
    setGenerateExcel(false);
  };

  return (
    <LayoutBasePagina
      titulo="Contas a Pagar"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarBotaoNovo={false}
          mostrarInputBusca={true}
          textoDaBusca={busca}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams({ busca: texto, pagina: "1" }, { replace: true })
          }
          mostrarDatas={true}
          dataInicio={dataInicio}
          dataFim={dataFim}
          mostrarBotaoFiltrar={true}
          aoMudarDataInicio={setDataInicio}
          aoMudarDataFim={setDataFim}
          aoClicarEmFiltrar={carregarDados}
        />
      }
      barraDeFiltros={
        <FerramentasDeFiltros
          mostraMultiSelect={true}
          title="Empresa"
          listaMultiSelect={empresas}
          selectedOptions={selectedEmpresas}
          handleChangeSelect={handleEmpresasChange}
          mostraCheckBox={true}
          listaCheckBox={statusOptions}
          checkBoxOptions={selectedStatus}
          handleChangeCheck={handleStatusChange}
          mostrarGerarRelatorio={true}
          aoClicarEmGerarRelatorio={handleGenerateReport}
          mostrarGerarExcel={true}
          aoClicarEmGerarExcel={handleGenerateExcel}
        />
      }
    >
      {/* Outros componentes */}
      <Box width="100%" display="flex">
        <Grid container spacing={2} margin={2}>
          <Grid item xs={12} sm={4} md={4}>
            {isLoading ? (
              <Card sx={{ p: 2 }}>
                <Skeleton height={150} />
              </Card>
            ) : (
              <DashboardCardQtd
                title="Quantidade"
                value={totalCount}
                footerText="Quantidade"
                gradientColors={["#FE6B8B", "#FF8E53"]}
                Icon={AutoAwesomeMotionIcon}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {isLoading ? (
              <Card sx={{ p: 2 }}>
                <Skeleton height={150} />
              </Card>
            ) : (
              <DashboardCardInadimplencia
                title="Inadiplência"
                value={totalInadimplencia}
                footerText="Inadiplência"
                gradientColors={["#FE6B8B", "#FF8E53"]}
                Icon={VerticalAlignBottomIcon}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {isLoading ? (
              <Card sx={{ p: 2 }}>
                <Skeleton height={150} />
              </Card>
            ) : (
              <DashboardCard
                title="Títulos"
                value={totalValorContasPagar}
                footerText="Total a pagar"
                gradientColors={["#FE6B8B", "#FF8E53"]}
                Icon={MonetizationOnIcon}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: "auto" }}
      >
        <Table>
          <TableHead>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={12}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Ações</TableCell>
              <TableCell sx={{ width: 150 }}>Empresa</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>NF</TableCell>
              <TableCell>Parcela</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Vencimento</TableCell>
              <TableCell>Cod.</TableCell>
              <TableCell sx={{ width: 300 }}>Fornecedor</TableCell>
              <TableCell>Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.R_E_C_N_O_}>
                <TableCell width={80}>
                  <IconButton
                    size="small"
                    // onClick={() =>
                    //   navigate(`/financeiro/cpagar/detalhe/${row.R_E_C_N_O_}`)
                    // }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: 150 }}>{row.EMPRESA}</TableCell>
                <SituacaoCell situacao={row.E2_SITUACAO} />
                <TableCell width={30}>{row.E2_ORIGEM}</TableCell>
                <TableCell width={30}>{row.E2_NUM}</TableCell>
                <TableCell width={10}>{row.E2_PARCELA}</TableCell>
                <TableCell width={10}>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(row.E2_VALOR)}
                </TableCell>
                <TableCell>{row.E2_VENCTOFOR}</TableCell>
                <TableCell>{row.E2_FORNECE}</TableCell>
                <TableCell sx={{ width: 300 }}>{row.E2_NOMFOR}</TableCell>
                <TableCell width={10}>{row.R_E_C_N_O_}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Enviroment.LISTAGEM_VAZIO}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={12}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Enviroment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={12}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Enviroment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { busca, pagina: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {generateExcel && (
        <ExportExcel
          data={excelData}
          fileName="contas-a-pagar.xlsx"
          onExportComplete={onExcelExportComplete}
        />
      )}
    </LayoutBasePagina>
  );
};
