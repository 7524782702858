import * as React from 'react';
import { Box, FormControlLabel, Checkbox, FormGroup, useTheme, Paper } from '@mui/material';

interface IStatusOption {
    id: number;
    label: string;
}

interface IStatusCheckboxesProps {
    options: IStatusOption[];
    checkBoxOptions: number[];
    handleChange: (selected: number[]) => void;
}

export const StatusCheckboxes: React.FC<IStatusCheckboxesProps> = ({
    options,
    checkBoxOptions,
    handleChange
}) => {
    const theme = useTheme();
    const handleCheckboxChange = (id: number) => {
        const newcheckBoxOptions = checkBoxOptions.includes(id)
            ? checkBoxOptions.filter(optionId => optionId !== id)
            : [...checkBoxOptions, id];
        handleChange(newcheckBoxOptions);
    };

    return (
        
        <Box  sx={{ display: 'flex', flexDirection: 'row', m: 1 }} >
            <FormGroup row>
                {options.map(option => (
                    <FormControlLabel
                        key={option.id}
                        control={
                            <Checkbox
                                checked={checkBoxOptions.includes(option.id)}
                                onChange={() => handleCheckboxChange(option.id)}
                                name={option.label}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </FormGroup>
        </Box>
    );
};
