import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IListagemContasReceber {
    EMPRESA: string; // 'C&S SEGURANCA MATRIZ' ou 'C&S SEGURANCA FILIAL'
    IDCHAVECLIENTE: string; // Exemplo: '01XXXX'
    A1_NOME: string;
    E1_NUM: string;
    E1_NFELETR: number;
    EMISSAO: string; // Data formatada como 'dd/MM/yyyy'
    BRUTO: number;
    F2_VALINSS: number;
    F2_VALISS: number;
    F2_VALIRRF: number;
    F2_VALCSLL: number;
    F2_VALPIS: number;
    F2_VALCOFI: number;
    LIQUIDO: number;
    VENCIMENTO: string; // Data formatada como 'dd/MM/yyyy'
    DIAS?: number | string; // Pode ser um número ou string vazia
    STATUS: 'VENCIDO' | 'RECEBIDO' | 'EM DIAS';
    SITUACAO: 'ABERTO' | 'BAIXADO';
    E1_TIPO: 'FATURADO' | 'REPACTUACAO';
    emi2: string; // Data original da emissão (sem formatação)
    venc2: string; // Data original do vencimento (sem formatação)
    C5_XCC: string | null; // Pode ser nulo caso não exista
    idexternodocliente: string; // Exemplo: '12-XXXX' ou '17-XXXX'
}

export interface IDetalheContasReceber {
    ID?:number;
    EMPRESA: string; // 'C&S SEGURANCA MATRIZ' ou 'C&S SEGURANCA FILIAL'
    IDCHAVECLIENTE: string; // Exemplo: '01XXXX'
    A1_NOME: string;
    E1_NUM: string;
    E1_NFELETR: number;
    EMISSAO: string; // Data formatada como 'dd/MM/yyyy'
    BRUTO: number;
    F2_VALINSS: number;
    F2_VALISS: number;
    F2_VALIRRF: number;
    F2_VALCSLL: number;
    F2_VALPIS: number;
    F2_VALCOFI: number;
    LIQUIDO: number;
    VENCIMENTO: string; // Data formatada como 'dd/MM/yyyy'
    DIAS?: number | string; // Pode ser um número ou string vazia
    STATUS: 'VENCIDO' | 'RECEBIDO' | 'EM DIAS';
    SITUACAO: 'ABERTO' | 'BAIXADO';
    E1_TIPO: 'FATURADO' | 'REPACTUACAO';
    emi2: string; // Data original da emissão (sem formatação)
    venc2: string; // Data original do vencimento (sem formatação)
    C5_XCC: string | null; // Pode ser nulo caso não exista
    idexternodocliente: string; // Exemplo: '12-XXXX' ou '17-XXXX'
}

type TListagemComTotalContasReceber = {
    data: IListagemContasReceber[];
    totalCount: number;
    totalValor: number;
}


// Serviço para busca sem paginação
const getAllWithoutPagination = async(
    filter = ''
): Promise<IListagemContasReceber[] | Error> => {
    try {
        const params = new URLSearchParams();
        if (filter) params.append('num', filter);

        const requestUrl = `/financeiro/creceber?${params.toString()}&semPaginacao=true`;

        const { data } = await Api.get(requestUrl);

        if (data) {
            return data;
        }

        return new Error('Erro ao listar os registros.');

    } catch (error) {
        console.error(error);
        return new Error((error as {message: string}).message || 'Erro ao listar os registros.');
    }
};

export const ContasReceberService = {
    getAllWithoutPagination
};
