import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Divider, InputAdornment, TextField, Typography } from "@mui/material"
import { useAuthContext } from "../../contexts"
import { useState } from "react"
import * as yup from 'yup'
import logo from '../../img/logoces.webp';
import { AccountCircle } from "@mui/icons-material";

const loginSchema = yup.object().shape({
    usuario: yup.string().required().min(3),
    senha: yup.string().required().min(5)
})

interface ILoginProps {
    children: React.ReactNode
}

export const Login : React.FC<ILoginProps> = ({children})=>{
    const {isAuthenticated, login} = useAuthContext()

    const [isLoading, setIsLoading] = useState(false)

    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');

    const [usuarioError, setUsuarioError] = useState('');
    const [senhaError, setSenhaError] = useState('');



   const handleSubmit = ()=> {
        setIsLoading(true)
        loginSchema
        .validate({usuario, senha}, {abortEarly: false})
        .then(dadosValidados => {
            login(dadosValidados.usuario, dadosValidados.senha)
            .then(()=>{
                setIsLoading(false)
            })
        }).catch((errors:yup.ValidationError)=>{
            setIsLoading(false)
            errors.inner.forEach(error => {
                if(error.path === 'usuario'){
                    setUsuarioError(error.message);
                }else if (error.path === 'senha'){
                    setSenhaError(error.message);
                }
            })
        })
    }
    
    if(isAuthenticated) return(
        <>{children}</>
    )
    
    return(
        <Box width="100vw" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center"              
        sx={{
            background: 'linear-gradient(135deg, #365F9E 10%, #9013FE 200%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <CardMedia
                component="img"
                image={logo}
                alt="ERACON HUB"
                style={{ width: 230, marginBottom: 20 }}
                
            />

            <TextField
                
                variant="standard"
                id="standard-basic"
                label= 'Usuario'
                value={usuario}
                disabled={isLoading}
                error={!!usuarioError}
                helperText={usuarioError}
                onChange={e => setUsuario(e.target.value)}
                onKeyDown={()=> setUsuarioError('')}
                autoComplete="new-username"
                InputProps={{
                    style: {
                        backgroundColor: 'transparent', // Fundo transparente
                        color: '#FFFFFF', // Texto branco
                        borderBottom: '1px solid #FFFFFF', // Borda inferior branca
                    },
                    disableUnderline: true, // Remover o underline padrão
                    classes: {
                        focused: 'focused' // Adicionar classe para foco
                    }
                }}
                InputLabelProps={{
                    style: {
                        color: '#FFFFFF', // Cor do texto do label
                    }
                }}
                FormHelperTextProps={{
                    style: {
                        color: '#FFFFFF', // Cor do texto de ajuda
                    }
                }}
                sx={{ marginBottom: 3, width: '300px' }} // Aumentar largura e adicionar margem inferior
                />
            <TextField
                variant="standard"
                label='Senha'
                type="password"
                value={senha}
                disabled={isLoading}
                error={!!senhaError}
                helperText={senhaError}
                onChange={e => setSenha(e.target.value)}
                onKeyDown={()=> setSenhaError('')}
                autoComplete="new-password"
                InputProps={{
                    style: {
                        backgroundColor: 'transparent', // Fundo transparente
                        color: '#FFFFFF', // Texto branco
                        borderBottom: '1px solid #FFFFFF', // Borda inferior branca
                    },
                    disableUnderline: true, // Remover o underline padrão
                    classes: {
                        focused: 'focused' // Adicionar classe para foco
                    }
                }}
                InputLabelProps={{
                    style: {
                        color: '#FFFFFF', // Cor do texto do label
                    }
                }}
                FormHelperTextProps={{
                    style: {
                        color: '#FFFFFF', // Cor do texto de ajuda
                    }
                }}
                sx={{ marginBottom: 3, width: '300px' }} // Aumentar largura e adicionar margem inferior
            />
                             <CardActions>
                    <Box width="100%" display="flex" justifyContent="center">
                        <Button 
                         variant="contained"
                         disabled={isLoading}
                         onClick={handleSubmit}
                         endIcon={isLoading ? <CircularProgress variant="indeterminate" color="inherit" size={10}/> : undefined}
                         sx={{
                            background: 'linear-gradient(135deg, #FFFFFF, #FFFFFF)',
                            color: 'black',
                            '&:hover': {
                                background: 'linear-gradient(135deg, #DCDCDC, #DCDCDC)',
                            },
                            marginBottom: 3, width: '300px'
                        }}
                        >
                            Entrar
                        </Button>
                    </Box>
                </CardActions>   

        </Box>
    )
}