import { Api } from "../../axios-config";

// Interface para o funcionário do Nexti
export interface IFuncionarioNexti {
  name: string;
  externalWorkplaceId: string;
  admissionDate: string;
  birthDate: string;
  enrolment: string;
}

// Função para buscar todos os funcionários do Nexti
const getAllWithoutPagination = async (): Promise<IFuncionarioNexti[] | Error> => {
  try {
    const { data } = await Api.get('/funcionarios-nexti');
    return data;
  } catch (error) {
    console.error('Erro ao buscar colaboradores do Nexti:', error);
    return new Error('Erro ao buscar colaboradores do Nexti');
  }
};

export const FuncionariosNextiServices = {
  getAllWithoutPagination,
};
