// src/pages/Dashboard.tsx

import React, { useState } from "react";
import { FiltroEmpresa } from "../components/FiltroEmpresa";
import { ValorTotalInadimplencia } from "../components/ValorTotalInadimplencia";
import { ValorTotalRecorrente } from "../components/ValorTotalRecorrente";
import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importando o locale em português
import { ValorLiquidadoNoMes } from "./ValorLiquidadoNoMes";
import { ValorFaturadoNoMes } from "./ValorFaturadoNoMes";
import { DiferencaFaturamento } from "./DiferencaFaturamento";
import { ProvisaoAnual } from "./BarraAnual";
import { InadimplenciaDataGrid } from "./InadimplenciaDataGrid";
import { TotalizadoresDonutChart } from "./TotalizadoresDonutChart";
import { ContasReceberDataGrid } from "./ContasReceberDataGrid";

// Configurar o dayjs para utilizar o locale em português
dayjs.locale("pt-br");

const Dashboard: React.FC = () => {
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState<string[]>([
    "C&S SEGURANCA MATRIZ",
  ]);

  // Obter o mês e ano atual em português
  const mesAnoAtual = dayjs().format("MMMM/YYYY").toUpperCase();
  const AnoAtual = dayjs().format("YYYY").toUpperCase();

  // Estados para armazenar os valores de provisão e faturamento
  const [totalProvisao, setTotalProvisao] = useState<number>(0);
  const [totalFaturado, setTotalFaturado] = useState<number>(0);

  return (
    <div style={{ padding: 20 }}>
      <Divider sx={{ marginBottom: "10px" }} />
      {/* Filtro de Empresa */}
      <FiltroEmpresa
        empresasSelecionadas={empresasSelecionadas}
        setEmpresasSelecionadas={setEmpresasSelecionadas}
      />
      {/* Título do Grid Container com Mês e Ano em Português */}
      <Typography variant="h6" gutterBottom>
        CENÁRIO DO MÊS - {mesAnoAtual}
      </Typography>
      <Divider sx={{ marginBottom: "10px" }} />
      {/* Grid Container para os Cards */}
      <Grid container spacing={1}>
        {/* <Grid item xs={12} md={3}>
          <QuantidadePorCentroDeCusto
            empresasSelecionadas={empresasSelecionadas}
          />
        </Grid> */}
        <Grid item xs={12} md={2.4}>
          <ValorTotalRecorrente
            empresasSelecionadas={empresasSelecionadas}
            setTotalProvisao={setTotalProvisao} // Passa a função de atualização
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <ValorFaturadoNoMes
            empresasSelecionadas={empresasSelecionadas}
            setTotalFaturado={setTotalFaturado}
            mesAtual={dayjs().month() + 1} // Obtém o mês atual dinamicamente
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <DiferencaFaturamento
            totalProvisao={totalProvisao}
            totalFaturado={totalFaturado}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <ValorLiquidadoNoMes empresasSelecionadas={empresasSelecionadas} />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <ValorTotalInadimplencia
            empresasSelecionadas={empresasSelecionadas}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
      {/* Título do Grid Container com Mês e Ano em Português */}
      <Typography variant="h6" gutterBottom>
        VENDIDO X REALIZADO - {AnoAtual}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <ProvisaoAnual empresasSelecionadas={empresasSelecionadas} />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <InadimplenciaDataGrid empresasSelecionadas={empresasSelecionadas} />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
      {/* Título do Grid Container com Mês e Ano em Português */}
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <ContasReceberDataGrid empresasSelecionadas={empresasSelecionadas} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
