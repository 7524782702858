import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  IPostosProtheus,
  PostosProtheusService,
} from "../../../../shared/services/api/comercial/clientes/PostosServices";
import GetAppIcon from "@mui/icons-material/GetApp";

// Importando o DataGrid do Material-UI
import { DataGrid, GridCloseIcon, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { PostosModal } from "./PostosModal";

interface ClienteModalProps {
  open: boolean;
  onClose: () => void;
}

// Definindo um novo tipo que estende IPostosProtheus para incluir os campos adicionais
interface ClienteAgrupado extends IPostosProtheus {
  contratosSet: Set<string>; // Conjunto para contratos únicos
  postosSet: Set<string>; // Conjunto para postos únicos
  totalContratos: number; // Contador total de contratos
  totalPostos: number; // Contador total de postos
}

export const ClienteModal: React.FC<ClienteModalProps> = ({
  open,
  onClose,
}) => {
  const [clientesData, setClientesData] = useState<ClienteAgrupado[]>([]);
  const [loading, setLoading] = useState(false);

  // Estado para controlar a exibição do PostosModal e o cliente selecionado
  const [selectedCodCliente, setSelectedCodCliente] = useState<number | null>(
    null
  );

  // Estado para controlar a exibição do PostosModal e o cliente selecionado
  const [postosModalOpen, setPostosModalOpen] = useState(false);

  //Estado pra controlar nome do cliente
  const [selectedCliente, setSelectedCliente] = useState<string>("");

  useEffect(() => {
    if (open) {
      setLoading(true);
      PostosProtheusService.getAllWithoutPagination()
        .then((result) => {
          if (result instanceof Error) {
            toast.error(result.message);
            return;
          }

          // Agrupando os clientes e somando as vagas
          const clientesAgrupados = result.reduce(
            (acc: ClienteAgrupado[], curr: IPostosProtheus) => {
              const found = acc.find(
                (item) => item.idexternodocliente === curr.idexternodocliente
              );
              if (found) {
                found.vagas += curr.vagas;

                // Adiciona ao conjunto contratosSet e postosSet
                found.contratosSet.add(curr.idcontrato);
                found.postosSet.add(curr.idposto);

                // Atualiza os totais com base no tamanho dos conjuntos
                found.totalContratos = found.contratosSet.size;
                found.totalPostos = found.postosSet.size;
              } else {
                acc.push({
                  ...curr,
                  contratosSet: new Set([curr.idcontrato]), // Inicializa o conjunto de contratos
                  postosSet: new Set([curr.idposto]), // Inicializa o conjunto de postos
                  totalContratos: 1, // Inicializa o contador de contratos
                  totalPostos: 1, // Inicializa o contador de postos
                });
              }
              return acc;
            },
            []
          );

          setClientesData(clientesAgrupados);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao carregar os dados dos clientes:", error);
          toast.error("Erro ao carregar os dados dos clientes.");
          setLoading(false);
        });
    }
  }, [open]);

  // Definir as colunas do DataGrid
  const columns: GridColDef[] = [
    { field: "empresa", headerName: "Empresa", flex: 1 },
    { field: "cliente", headerName: "Cliente", flex: 1 },
    {
      field: "totalContratos",
      headerName: "Total de Contratos",
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "totalPostos",
      headerName: "Total de Postos",
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "vagas",
      headerName: "Total de Vagas",
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
  ];

  // Função para exportar os dados para XLSX
  const handleExport = () => {
    const exportData = clientesData.map((row) => ({
      "Id Externo": row.idexternodocliente,
      Cliente: row.cliente,
      "Total de Vagas": row.vagas,
      "Total de Contratos": row.totalContratos,
      "Total de Postos": row.totalPostos,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clientes");

    XLSX.writeFile(workbook, "clientes_dados.xlsx");
  };
  // Função para abrir o PostosModal ao clicar duas vezes em um cliente
  const handleRowDoubleClick = (params: any) => {
    setSelectedCodCliente(params.row.idexternodocliente);
    setPostosModalOpen(true);
    setSelectedCliente(params.row.cliente);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "90%",
          height: "80%",
          p: 4,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          textAlign: "center",
          margin: "5% auto",
          marginBottom: "50px",
        }}
      >
        <AppBar
          position="static"
          sx={{
            background: "linear-gradient(90deg, #505590,#4a86c9,#2fbbfa);",
            boxShadow: "none", // Remove a sombra padrão do AppBar
            borderRadius: "8px 8px 0 0", // Bordas arredondadas no topo
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              LISTA DE CLIENTES
            </Typography>
            <IconButton sx={{ color: "#fff" }} onClick={handleExport}>
              <GetAppIcon />
            </IconButton>
            <IconButton edge="end" color="inherit" onClick={onClose}>
              <GridCloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading ? (
          <LinearProgress variant="indeterminate" />
        ) : (
          <div
            style={{
              height: "85%",
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <DataGrid
              rows={clientesData} // Dados dos clientes
              columns={columns} // Colunas definidas
              getRowId={(row) => row.idexternodocliente} // Identificador único
              onRowDoubleClick={handleRowDoubleClick} // Adiciona evento de duplo clique
            />
          </div>
        )}
        {/* PostosModal - Modal para mostrar os postos ao clicar duas vezes em um cliente */}
        <PostosModal
          open={postosModalOpen}
          onClose={() => setPostosModalOpen(false)}
          idexternodocliente={String(selectedCodCliente)} // Converter para string aqui
          nomeCliente={selectedCliente}
        />
      </Box>
    </Modal>
  );
};
