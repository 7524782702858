// src/pages/contasReceber/ListagemDeContasReceber.tsx
import React from "react";
import { LayoutBasePagina } from "../../../shared/layouts";
import DashboardCardsReceber from "./components/DashBoardCards";

export const ListagemDeContasReceber: React.FC = () => {
  return (
    <LayoutBasePagina titulo="CONTAS A RECEBER ">
      <DashboardCardsReceber />
    </LayoutBasePagina>
  );
};
