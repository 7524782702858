// src/components/FiltroEmpresa.tsx

import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Chip,
  Box,
} from "@mui/material";

interface FiltroEmpresaProps {
  empresasSelecionadas: string[];
  setEmpresasSelecionadas: (value: string[]) => void;
}

export const FiltroEmpresa: React.FC<FiltroEmpresaProps> = ({
  empresasSelecionadas,
  setEmpresasSelecionadas,
}) => {
  const handleChange = (
    event: SelectChangeEvent<typeof empresasSelecionadas>
  ) => {
    const value = event.target.value;
    setEmpresasSelecionadas(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      style={{ marginBottom: "20px", width: "400px" }}
    >
      <InputLabel id="empresa-select-label">Empresa</InputLabel>
      <Select
        labelId="empresa-select-label"
        id="empresa-select"
        multiple
        value={empresasSelecionadas}
        onChange={handleChange}
        inputProps={{ "aria-label": "Select multiple companies" }}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        label="Empresa"
      >
        <MenuItem value="C&S SEGURANCA MATRIZ">C&S SEGURANCA MATRIZ</MenuItem>
        <MenuItem value="C&S SEGURANCA FILIAL">C&S SEGURANCA FILIAL</MenuItem>
      </Select>
    </FormControl>
  );
};
